// *************radiobutton Css opened******************//
.radiobutton {
  font-size: $fs-primary;
  padding: 2px 10px 5px 1px;
}

.radioInput {
  height: 1rem;
  width: 2rem;
}

.radioButtonLg {
  height: 1.4rem;
  width: 2rem;
}

.fdradio {
  display: flex;
  align-items: center;
}

// *************radiobutton Css closed******************//


// *************float Css opened******************//
.floatRight {
  float: $float-right;

}

.floatLeft {
  float: $float-left;
}

// *************float Css closed******************//


// *************form table Css opened******************//
.bg-tableHead {
  background-color: #e8f3ff;
}

.table-collapse {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.paddingY-th-td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.bg-dlog-thead {
  background-color: #e8f3ff42
}


// *************form table Css closed******************//


// *************overflow Css opened******************//
.overflowX-auto {
  overflow-x: $overflow-auto;
}

.overflowY-auto {
  overflow-y: $overflow-auto;
}

// *************overflow Css closed******************//

// ***********dialogBox css opened*****************//

#dialog-bgCard {
  background: #edf3ef
}

.bgImei-head {
  background-color: #f7f7f7;
}

// ***********dialogBox css closed*****************//


// timeInput
.timeInput {
  margin-top: 6px;
  display: block !important;
  width: 100%;
  padding: 2px 0;
  margin-top: 8px
}

// mediaQuery


.form-title {
  padding: 1rem 4rem;
  border-bottom: 1px solid #c1c1c1;
}

// employee creation form css
.form-item {

  flex-direction: $flex-row;

}

.form-label {
  width: $width !important;
}

.form-control {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 1.3rem + 2px) !important;
  padding: 0.65rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #3F4254 !important;
  background-color: #ffffff !important;
  background-clip: padding-box !important;
  border: 1px solid #E4E6EF !important;
  border-radius: 0.42rem !important;
  box-shadow: none !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.status{
  position: relative;
  justify-content: center;
}