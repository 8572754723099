// Body
body {
    background: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {

    // Wrapper
    .wrapper {

        // Fixed Header
        .header-fixed & {
            padding-top: get($header-config, desktop, fixed, height);
        }

        // Fixed Content Head
        .header-fixed.subheader-fixed.subheader-enabled & {
            padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
        }

        // Fixed Aside
        .aside-fixed & {
            padding-left: get($aside-config, base, width);
        }

        // Minimize Aside
        .aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
            padding-left: get($aside-config, base, minimized-width);
        }
    }


    // Container
    .container,
    .container-fluid {
        padding: 0 get($page-padding, desktop);
    }

    .default-container {
        margin-top: -40px;
    }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {

    // Wrapper
    .wrapper {

        // Fixed Header
        .header-mobile-fixed & {
            padding-top: get($header-config, tablet-and-mobile, fixed, height);
        }

        // Fixed Subheader
        .header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
            padding-top: get($header-config, tablet-and-mobile, fixed, height) + get($subheader-config, fixed, height-mobile);
        }
    }

    // Container
    .container,
    .container-fluid {
        max-width: none;
        padding: 0 get($page-padding, tablet-and-mobile);
    }
}
