@font-face {
  font-family: "SAP-icons";
  src: url("../node_modules/@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/SAP-icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* You can add global styles to this file, and also import other style files */

@import "../node_modules/swiper/swiper.scss";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./assets/metronic/themes/style.angular.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./assets/style/index.scss";
@import "./academysite/academysite.component.scss";
@import "./assets/css/meanmenu.min.css";
@import "./assets/css/animate.min.css";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

// @import "./assets/css/flaticon.css";
// @import "./assets/css/fontawesome.css";
/* You can add global styles to this file, and also import other style files */

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-left-color: $bg-white;
  border-right-color: $bg-white;
  border-top-color: $bg-white;
  border-bottom: 1px solid #2196f3;
  border-left: none;
  border-right: none;
  border-top: none;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-left-color: $bg-white;
  border-right-color: $bg-white;
  border-top-color: $bg-white;
  border-bottom: 1px solid #2196f3;
  border-left: none;
  border-right: none;
  border-top: none;
}

.grid__input::-webkit-inner-spin-button,
.grid__input::-webkit-outer-spin-button {
  -webkit-appearance: $display-none;
}

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: $zero;
}

.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: $zero;
}

.p-datatable .p-datatable-header {
  background: $f2f4f8;
  color: #343a3f;
  border: 1px solid #dde1e6;
  border-width: 1px 0 1px 0;
  padding: 0.5rem 1rem;
  font-weight: calc($fw-md + 100);
}

.p-datatable .p-datatable-footer {
  background: $f2f4f8;
  color: #343a3f;
  border: 1px solid #dde1e6;
  border-width: 0 0 1px 0;
  padding: 0.5rem 1rem;
  font-weight: calc($fw-md + 100);
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: $bg-gray;
  border: 1px solid #eae5e5;
  box-sizing: $border-box;
  padding: 0 4px;
  z-index: $zero;
  align-items: $center;
  height: calc($height - 230px);
  overflow: $overflow-hidden;
  padding-right: 0px;
  transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tfoot > tr > td {
  text-align: $align-left;
  padding: 0.5rem 1rem;
  border: 1px solid #dde1e6;
  border-width: 0 0 1px 0;
  font-weight: calc($fw-md + 100);
  color: #343a3f;
  background: #f2f4f8;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #697077;
  margin-left: 0.5rem;
}

.p-datatable-scrollable-header-box {
  padding-right: 0 !important;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: $bg-white;
  background: #44a1d9;
  margin-left: 0.5rem;
}

h1.no_result {
  text-align: $center;
  font-size: 1.5rem;
  font-family: "Metric";
  color: #0a6ed1;
}

//table-rupak
.table-visible {
  overflow: visible !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #dde1e6;
  color: #343a3f;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #697077;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f2f4f8;
  color: #1174c0;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #1174c0;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: #dde1e6;
  color: #1174c0;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #1174c0;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #90c9f5;
  outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #343a3f;
  transition: box-shadow 0.2s;
  outline-color: #90c9f5;
}

.p-datatable .p-datatable-tbody > tr > td {
  background-color: white;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 0 4px;
  z-index: 0;
  align-items: center;
  height: 30px;
  overflow: hidden;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 1rem;
  height: 1rem;
  color: #878d96;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #343a3f;
  border-color: #121619;
  background: transparent;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #90c9f5;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #44a1d9;
  color: #ffffff;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #44a1d9;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #44a1d9;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #dde1e6;
  color: #343a3f;
}

.p-datatable .p-column-resizer-helper {
  background: #1174c0;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f2f4f8;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #fcfcfc;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #44a1d9;
  color: #ffffff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #ffffff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #ffffff;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.25rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.25rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.125rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.25rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.25rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 0.625rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 0.625rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 0.3125rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 0.625rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 0.625rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-dataview-header {
  background: #f2f4f8;
  color: #343a3f;
  border: 1px solid #dde1e6;
  border-width: 1px 0 1px 0;
  padding: 0.5rem 1rem;
  font-weight: 600;
}

.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #343a3f;
  border: 0 none;
  padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #dde1e6;
  border-width: 0 0 1px 0;
}

.p-dataview .p-dataview-footer {
  background: #f2f4f8;
  color: #343a3f;
  border: 1px solid #dde1e6;
  border-width: 0 0 1px 0;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}

.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}

.p-dataview .p-dataview-emptymessage {
  padding: 0.5rem;
}

.p-paginator {
  background: #ffffff;
  color: #697077;
  border: 0 solid #dde1e6;
  padding: 0.5rem 1rem;
  border-radius: 1px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #697077;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 1px;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #dde1e6;
  border-color: transparent;
  color: #343a3f;
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.p-paginator .p-paginator-last {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 2.357rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #697077;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #697077;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 1px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #44a1d9;
  border-color: #44a1d9;
  color: #ffffff;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #dde1e6;
  border-color: transparent;
  color: #343a3f;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-10 {
  width: 10%;
}

.width-percent-15 {
  width: 15%;
}

.width-percent-20 {
  width: 20%;
}

.width-percent-30 {
  width: 30%;
}

.min-width-6 {
  min-width: 6.75rem !important;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-100 {
  width: 100%;
}

.width-120px {
  width: 120px !important;
}

.width-150px {
  width: 150px !important;
}

.background-thead {
  background-color: #e8f3ff;
}

/* Sweet alert */

.swal2-title {
  display: inline-block !important;
}

body.swal2-height-auto {
  height: inherit !important;
}

.swal2-popup.swal2-toast {
  padding: 10px 12px !important;
  display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
  text-align: left !important;
  margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
  margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
  content: "";
}

@keyframes glowing {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.glowing {
  animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.8);
  z-index: 99999 !important;
  width: 100%;
  height: 100%;
}

.root-initial-loader i {
  position: absolute;
  color: rgb(91, 167, 234);
  z-index: 100000 !important;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
  -webkit-animation: fa-spin 1s infinite linear;
  animation: fa-spin 1s infinite linear;
}

ngx-spinner .loading-text {
  font-weight: bold;
  color: white;
  font-size: 1.3em;
}

#kt_aside {
  background: white;
}

#kt_aside_menu_wrapper {
  box-shadow: 1px 0px 0px rgb(218 218 218) !important;
}

#kt_header {
  box-shadow: 0px 1px 0px rgb(218 218 218) !important;
  background: white;
  padding: 3px 5px;
  height: 40px;
}

.fd-form-label {
  font-size: 1.1rem !important;
  font-family: Metric !important;
}

.fd-select__control {
  padding: 0 0 0 5rem !important;
  margin-top: 2px !important;
}

.scrolleditem {
  text-align: center;
  font-size: 24px;
  padding: 5px;
  border: 1px solid #f5f6f8;
}

.scrolleditem:hover {
  background: #0a6ed1;
  color: white;
  padding: 5px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #fff !important;
  background-color: #0a6ed1 !important;
  box-shadow: #0a0a0a;
}

.ng-option-label {
  font-size: 15px !important;
}

.ng-dropdown-panel .scroll-host {
  margin-top: -10px;
  // width: 100% !important;
  border: 1px dotted #89919a !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #0a6ed1 !important;
  color: #fff !important;
}

@media (min-width: 1281px) {
  .custom-ngselect .ng-dropdown-panel .ng-dropdown-panel-items {
    top: 20%;
    min-height: 450px;
    right: 20px;
    position: fixed !important;
    max-height: 450px !important;
    animation: selectmove 0.2s;
  }

  .custom-ngselect .ng-dropdown-panel .scroll-host {
    overflow: hidden;
    overflow-y: auto;
    width: 250px;
    display: block;
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%) !important;
    background: white;
    margin-top: 0 !important;
    -webkit-overflow-scrolling: touch;
    height: 450px;
  }

  .custom-ngselect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #0a6ed1 !important;
    color: white !important;
  }
}

@media (min-width: 1025px) and (max-width: 1880px) {
  .custom-ngselect .ng-dropdown-panel .ng-dropdown-panel-items {
    width: 20vw !important;
    box-shadow: -3px 2px 6px 1px rgb(235 235 235) !important;
    -webkit-box-shadow: -3px 2px 6px 1px rgb(235 235 235) !important;
    -moz-box-shadow: -3px 2px 6px 1px rgb(235 235 235) !important;
    border: 1px solid #f9f9f9 !important;
  }

  .custom-ngselect .ng-dropdown-panel .ng-dropdown-panel-items {
    top: 20%;
    min-height: 450px;
    right: 20px;
    position: fixed !important;
    max-height: 450px !important;
    animation: selectmove 0.2s;
  }

  .custom-ngselect .ng-dropdown-panel .scroll-host {
    overflow: hidden;
    overflow-y: auto;
    width: 250px;
    display: block;
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%) !important;
    background: white;
    margin-top: 0 !important;
    -webkit-overflow-scrolling: touch;
    height: 450px;
  }

  .custom-ngselect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #0a6ed1 !important;
    color: white !important;
  }
}

@keyframes selectmove {
  from {
    right: -100px;
  }

  to {
    right: 20px;
  }
}

.fd-form-item {
  font-family: Metric !important;
}

.performance-facts {
  /* border: 1px solid black; */
  line-height: 1.4;
  font-size: small;
  float: left;
  width: 100%;
  padding: 0.5rem;
}

.performance-facts table {
  border-collapse: collapse;
}

.performance-facts__title {
  font-weight: bold;
  font-size: 2rem;
  margin: 0 0 0.25rem 0;
}

.performance-facts__header {
  border-bottom: 1px solid #ebedf3;
  padding: 0 0 0.25rem 0;
  margin: 0 0 0.5rem 0;
}

.performance-facts__header p {
  margin: 0;
}

.performance-facts__table,
.performance-facts__table--grid,
.performance-facts__table--small {
  width: 100%;
}

.performance-facts__table thead tr th,
.performance-facts__table--grid thead tr th,
.performance-facts__table--small thead tr th,
.performance-facts__table thead tr td,
.performance-facts__table--grid thead tr td,
.performance-facts__table--small thead tr td {
  border: 0;
}

.performance-facts__table th,
.performance-facts__table--grid th,
.performance-facts__table--small th,
.performance-facts__table td,
.performance-facts__table--grid td,
.performance-facts__table--small td {
  font-weight: normal;
  text-align: left;
  padding: 0.25rem 0;
  /* border-top: 1px solid black; */
  white-space: nowrap;
}

.performance-facts__table td:last-child,
.performance-facts__table--grid td:last-child,
.performance-facts__table--small td:last-child {
  text-align: right;
}

.performance-facts__table .blank-cell,
.performance-facts__table--grid .blank-cell,
.performance-facts__table--small .blank-cell {
  width: 1rem;
  border-top: 0;
}

.performance-facts__table .thick-row th,
.performance-facts__table--grid .thick-row th,
.performance-facts__table--small .thick-row th,
.performance-facts__table .thick-row td,
.performance-facts__table--grid .thick-row td,
.performance-facts__table--small .thick-row td {
  border-top-width: 5px;
}

.small-info {
  font-size: 0.95rem !important;
}

.performance-facts__table--small {
  border-bottom: 1px solid #999;
  margin: 0 0 0.5rem 0;
}

.performance-facts__table--small td:last-child {
  text-align: left;
}

.performance-facts__table--small th,
.performance-facts__table--small td {
  border: 0;
  padding: 0;
}

.performance-facts__table--grid {
  margin: 0 0 0.5rem 0;
}

.performance-facts__table--grid td:last-child {
  text-align: left;
}

.performance-facts__table--grid td:last-child::before {
  content: "•";
  font-weight: bold;
  margin: 0 0.25rem 0 0;
}

.text-center {
  text-align: center;
}

.ng-select .ng-select-container {
  border: 1px solid #89919a !important;
}

.fd-checkbox:checked + .fd-checkbox__label:before {
  content: "✔" !important;
}

label {
  margin-bottom: -0.2rem;
}

.fd-input:last-child {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 1rem !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.ng-select .ng-select-container {
  height: 32px !important;
  min-height: 32px !important;
  margin-bottom: 1rem;
}

.ng-dropdown-panel {
  border: 0 !important;
}

.ng-select .ng-select-container {
  border-radius: 0 !important;
  margin-top: 0.25rem !important;
}

.sap-icon--excel-attachment:before {
  content: "🖺" !important;
}

.sap-icon--add:before {
  content: "✚" !important;
}

.sap-icon--filter:before {
  content: "⬙" !important;
}

.sap-icon--slim-arrow-down:before {
  content: "⌵" !important;
}

.sap-icon--less:before {
  content: "▬" !important;
}

.sap-icon--activate:before {
  content: "⬘" !important;
}

.hidden-filter {
  background: $bg-gray;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #8080805c;
}

.style-this {
  font-size: 18px !important;
  font-weight: 600;
  text-shadow: -1px -1px 0px #ffffff, 2px 2px 0px #f8f8f8, 3px 3px 0px #dde9f7;
  letter-spacing: 1px;
  width: 100%;
  min-width: 540px;
}

/* breadcrumb */
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #0a6ed1 !important;
}

.p-paginator .p-paginator-next {
  min-width: 2rem !important;
  height: 2rem !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem !important;
  height: 2rem !important;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-top: 5px !important;
}

.primeng-paging-container .p-dropdown {
  display: inline-flex;
  position: relative;
}

.primeng-paging-container .p-dropdown-panel {
  background: white;
  color: #495057;
  border: 0 none;
  padding: 3px;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.fa-eye {
  color: lightseagreen !important;
}

.badge-edit {
  background: linear-gradient(to right, #3a6073, #0a6ed1);
  padding: 4px;
}

.badge-info {
  // background: #1a2980; /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #26d0ce, #1a2980); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #26d0ce, #1a2980);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 3.5px;
}

.badge-pdf {
  background: linear-gradient(to right, #b10f12, #ca2425);
  padding: 3.5px;
}

.badge-delete {
  background: #ed213a;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f40f02, #ed213a);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f40f02, #ed213a);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 3.5px;
}

.fd-title--h3 {
  font-size: 20px !important;
  font-family: system-ui !important;
}

.sap-icon--inbox:before {
  content: "☑" !important;
}

.fd-action-bar {
  border-bottom: 1px solid #dde1e6 !important;
  box-shadow: none !important;
}

.additional {
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #dde1e6;
}

.additional-header {
  padding: 5px 20px;
  border-bottom: 1px dashed #dde1e6;
}

.additional-header h3 {
  margin-bottom: 0;
  font-size: 16px;
  color: black;
}

.additional-body {
  padding: 20px 5px;
}

.fd-form-label {
  font-weight: 500 !important;
  color: #0a0a0a !important;
}

.fd-input.is-error,
.fd-input.is-error.is-hover,
.fd-input.is-error:hover {
  border-color: #da1111 !important;
}

.fd-input.is-success,
.fd-input.is-success.is-hover,
.fd-input.is-success:hover {
  border-color: #89919a !important;
}

.fd-alert--error:before {
  content: "❌" !important;
}

.fd-alert--warning:before {
  content: "⛔" !important;
}

// .table thead th {
//     border-bottom: 2px solid #89919a !important;
// }

.table-bordered td,
.table-bordered th {
  border: 1px solid #dad9d9 !important;
}

/* loader */

/**
  * main style
  */
.timeline-item {
  background-color: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  padding: 12px;
  margin: 5%;
  max-width: 100%;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */
.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 500px;
  right: 0;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 800px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

default-brand {
  border-bottom: 1px solid #d7dae1;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

.fd-message-strip--information:before {
  content: "🛈" !important;
}

.sap-icon--refresh:before {
  content: "↺" !important;
}

.sap-icon--decline:before {
  content: "✖" !important;
}

.sap-icon--appointment-2:before {
  content: "📆" !important;
}

.fd-message-strip--error:before {
  content: "⛔" !important;
}

button.voucherType .sap-icon--slim-arrow-right:before {
  font-size: 15px !important;
  content: "▷" !important;
}

.sap-icon--delete:before {
  content: "✂" !important;
}

.sap-icon--arrow-right:before {
  content: "➤" !important;
}

.sap-icon--action-settings:before {
  content: "⚙" !important;
}

::ng-deep.custom-ngselect .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  text-align: center;
  position: absolute;
  bottom: 0;
  border: 2px solid #0a6ed1;
  width: 100%;
  font-weight: bolder;
}

.form-container {
  margin-top: -50px !important;
}

// new styling
// .global_container {
//     margin-top: -35px;
// }
/* tab */
@media only screen and (min-device-width: 450px) and (max-device-width: 992px) {
  .device_container {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 450px) {
  .device_container {
    margin-top: 15px;
  }
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0,
                  rgba(255, 255, 255, 0.2) 20%,
                  rgba(255, 255, 255, 0.5) 60%,
                  rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton_spacer {
  width: 10px;
  height: 10px;
  display: inline-block;
}

//pdf color
.text-pdf {
  color: #45b39d !important;
}

.text-edit {
  color: #0a6ed1 !important;
}

.text-danger {
  color: #e74c3c !important;
}

::ng-deep.fd-tabs__content {
  flex-grow: 1;
  overflow-y: unset !important;
}

// ng dropdown white-space changed
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

// p-tree icon changed

.permission_trees .p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: black;
  font-size: 14px;
}

.permission_trees .p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 18px;
  height: 17px;
  color: #495057;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.permission_trees .p-checkbox {
  padding-right: 2px;
  padding-left: 2px;
}


//table header

.fd-toolbar--info {
  background-color: #0a6ed1 !important;
  color: #ffffff !important;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.blink {
  text-decoration: blink;
  border: 1px solid red !important;
  color: red !important;
  animation: blinker 0.6s ease-in-out 0s infinite alternate none;
}


button.btn {
  font-weight: 400 !important;
  background: #0a6ed1 !important;
  color: white !important;
  border: 1px solid #0a6ed1 !important;
  padding: 0 0 0 0.5625rem !important;
  height: 2.25rem;
  max-height: 2.25rem;
  padding-right: 10px !important;
  padding-left: calc(0.625rem - var(0.7rem, 0.0625rem)) !important;
}

button.bt {
  font-weight: 400 !important;
  background: #ffffff !important;
  color: white !important;
  border: 1px solid #ffffff !important;
  padding: 0 0 0 0.5625rem !important;
  height: 2.25rem;
  max-height: 2.25rem;
  padding-right: 10px !important;
  padding-left: calc(0.625rem - var(0.7rem, 0.0625rem)) !important;
}

.capitalize {
  text-transform: capitalize;
}



// top bar date click start
.defaultdate .fd-input {
  display: none !important;
}

.defaultdate .pickerCalender {
  display: block !important;
  position: absolute;
  left: -79px;
}

.defaultselect .fd-select {
  width: 58%;
  position: relative;
  left: 140px;
}

@media only screen and (max-width: 900px) {
  .defaultselect .fd-select {
    width: 87%;
    position: relative;
    left: -45px;
  }

  .search-input {
    display: none;
  }

  .roles {
    padding: 10px !important;
  }
}

// top bar date click end
.delete-icon {
  color: #fffdfd !important;
}

.delete_button {
  background: #ff787859 !important;
  border: 1px solid red !important;
  // height: 33px;
}
.delet-icon{
  color: #de0000;
}

.sn_css {
  padding-left: 18px;
  padding-bottom: 14px;
  font-weight: bold;
}

@media only screen and (min-device-width: 450px) and (max-device-width: 992px) {
  .global_container {
    margin-top: 8px;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 450px) {
  .global_container {
    margin-top: 6px;
    overflow-x: hidden;
  }
}

// drag and drop  css open
.drag-handle {
  display: flex;
  align-items: center;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/*
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding: 4px;
  } */
.cdk-drag-preview {
  background-color: white;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: inline-table;
}

.cdk-drag-preview td {
  padding: 0 16px;
}

.cdk-drag-placeholder {
  opacity: 0;
}

//   drag and drop css closed

.ng-select .ng-clear-wrapper {
  left: 7px !important;
}

.voucherNo {
  font-weight: bold;
  font-size: medium;
}

// sidebar

.sidebarright {
  height: 100%;
  position: absolute;
  right: 1px;
  width: 42% !important;
  top: 80px;
  display: flex;
  flex-direction: row-reverse;
  z-index: 1;
}

// sidebar closed

.employee-tab .fd-tabs__process-icon:before {
  display: none !important;
}

@media (min-width: 600px) {
  .fd-container {
    padding: 0px !important;
  }
}


.fd-container {
  position: relative;
  top: 0px !important;
}

.table thead th,
.table thead td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.text-upload {
  font-size: 1rem;
}

.simple_card {
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.2) !important;
}

.parent {
  min-height: 300px;
  /* if you prefer */
}

.parent {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

// files uploader styles end

/* contact card  */
// .contact-card-div {
//     display: flex;
//     flex-direction: row;
// }

// .contact-card-div-width {
//     width: 100%;
// }

.vl {
  border-left: 1px solid lightgrey;
  height: inherit;
}

.text-margin {
  margin-left: 27px !important;
}

.small-text {
  font-size: 13px !important;
}

.label-div label {
  width: 110px;
}

.label-div .fd-container {
  display: flex;
  flex-wrap: wrap;
  flex: auto;
  padding: 0 0.25rem;
}

/* contact card closed  */

.form_array_input input {
  min-width: 11.75rem !important;
}

// report filter
.excel_buttons {
  border: none !important;
  width: inherit !important;
}

.viewText {
  font-weight: 300;
  font-size: 1rem;
}

// DEAL TAB CSS START
.tablist {
  .fd-tabs--compact .fd-tabs__icon {
    font-size: 1rem !important;
    width: 1.875rem !important;
    height: 1.875rem !important;
    color: white !important;
    background-color: #2787cb !important;
  }

  .fd-tabs--process .fd-tabs__item {
    margin: 0px 1.25rem 0px 6px;
  }

  .fd-tabs__process-icon {
    display: none !important;
  }

  .fd-tabs__wrapper {
    flex-grow: 1;
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    // overflow-x: auto;
  }

  .fd-tabs--compact .fd-tabs__label:first-child {
    padding-bottom: 0.25rem;
    // displaye: none;
    display: none !important;
  }

  .fd-tabs--process .fd-tabs__label {
    white-space: nowrap;
    max-width: 16.5rem;
    line-height: 1.875rem;
    overflow: hidden;
    font-size: 17px;
  }

  .fd-tabs__tag {
    font-size: 18px !important;
  }

  .fd-tabs__item[aria-selected="true"] .fd-tabs__icon:after {
    background-color: var(--fdTabs_Selected_Color, var(--sapSelectedColor));
  }
}

// DEAL TAB DESIGN END

.debit .p-treetable {
  position: relative;
  border: 1px solid black !important;
}

.multipleledger {
  padding-top: 15px !important;
  padding-left: 21px !important;
  text-align: center;
}

.multipleledger-button {
  display: flex;
  padding-top: 15px !important;
  justify-content: center;
}

.labelstyle {
  font-size: 14px !important;
  color: white !important;
}

.multipleledgerbranch {
  position: relative;
  top: 6px;
}

.imebutton {
  position: relative;
  top: -5px;
}

.branch_image {
  z-index: 999;
  opacity: 0;
  width: 320px;
  height: 200px;
  position: absolute;
  right: 0px;
  left: 0px;
  margin-right: auto;
  margin-left: auto;
}

.employee-salary-label {
  display: flex;
  font-size: 16px;
  padding: 2px 10px 5px 1px;
}

.employee-salary-input {
  height: 1.4rem;
  width: 2rem;
}

.padding-0-25 {
  padding: 0px 25px !important;
}

.padding-10-15 {
  padding: 10px 15px !important;
}

#nodatafoundtable {
  box-shadow: 0 0 0 0.0625rem rgb(0 0 0 / 42%), 0 0.125rem 0.5rem 0 rgb(0 0 0 / 30%);
}

.positionexcel {
  position: relative;
  top: 20px;
}

.thbgcolor {
  background: #f8fafb;
}

// .flex-items-gap {
//     display: flex !important;
//     align-items: center !important;
//     gap: 15px !important;
// }

.dealtopdiv {
  // margin-top: -30px;
  background-color: white;
}

.dealtask {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dealexcalamation {
  color: rgb(241, 122, 122);
  font-size: 6rem;
}

.dealformcontainer {
  border-bottom: 1px solid #cccccc;
  margin-top: 20px;
}

.dealcardlist {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.dealstatus {
  color: rgb(10 110 209);
  margin-left: 38px;
}

.position-relative-top-3px {
  position: relative;
  top: 3px;
}

.multipleunitproductaddbutton {
  padding: 3px 0 0 3px;
  display: flex;
  justify-content: center;
  margin: auto auto;
}

.ageingth {
  background: #0a6ed1;
  border-color: white !important;
}

.ageingtrth {
  border-color: white !important;
  text-align: center;
  background: #0a6ed1;
  font-size: 15px;
  color: white;
}

.productlistreport {
  list-style: none;
  font-size: 15px;
  margin-left: -26px;
}

//Neon
.Neon {

  font-size: 14px;
  color: #494949;
  position: relative;
}

.Neon * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.Neon-input-dragDrop {
  display: block;
  width: 250px;
  margin: 0 auto 25px auto;
  padding: 25px;
  color: #8d9499;
  color: #97a1a8;
  background: #fff;
  border: 2px dashed #c8cbce;
  text-align: center;
  -webkit-transition: box-shadow 0.3s, border-color 0.3s;
  -moz-transition: box-shadow 0.3s, border-color 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.Neon-input-dragDrop .Neon-input-icon {
  font-size: 48px;
  margin-top: -10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Neon-input-text h3 {
  margin: 0;
  font-size: 18px;
}

.Neon-input-text span {
  font-size: 12px;
}

.Neon-input-choose-btn.blue {
  color: #008bff;
  border: 1px solid #008bff;
}

.Neon * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.Neon-input-dragDrop {
  display: block;
  width: 250px;
  margin: 0 auto 25px auto;
  padding: 25px;
  color: #8d9499;
  color: #97a1a8;
  background: #fff;
  border: 2px dashed #c8cbce;
  text-align: center;
  -webkit-transition: box-shadow 0.3s, border-color 0.3s;
  -moz-transition: box-shadow 0.3s, border-color 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.Neon-input-dragDrop .Neon-input-icon {
  font-size: 48px;
  margin-top: -10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Neon-input-text h3 {
  margin: 0;
  font-size: 18px;
}

.Neon-input-text span {
  font-size: 12px;
}

.Neon-input-choose-btn.blue {
  color: #008bff;
  border: 1px solid #008bff;
}

.Neon-input-choose-btn {
  display: inline-block;
  padding: 8px 14px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  color: #8d9496;
  border-radius: 3px;
  border: 1px solid #c6c6c6;
  vertical-align: middle;
  background-color: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.photo {
  display: inline-block;
  position: relative;
}

.photo-citizen {
  display: inline-block;
  position: relative;
  width: 100% !important;
}

.image-size {
  width: 150px;
  height: 150px;
  box-shadow: 0 0 35px 0 rgb(0 0 0 / 15%);
  border-radius: 50%;
}

.image-size-citizenship {
  width: 100%;
  height: auto;
  box-shadow: 0 0 35px 0 rgb(0 0 0 / 15%);
}

.photo-upload {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: -5px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: 3px solid #0a6ed1;
  background-color: #fff;
  padding: 0px;
  text-align: center;
  z-index: 100;
  border-radius: 50%;
}

.photo-upload i {
  color: #0a6ed1;
  font-size: 22px;
  line-height: 41px;
}

.photo-upload i:hover {
  animation: move 0.3s;
}

//   voucher type css
.form-itemm {
  display: $display-flex;
  flex-direction: $flex-row;
}

.e-container {
  display: $display-flex;
  flex-wrap: $flex-wrap;
  width: $width;
  gap: 30px;
  margin-bottom: 2rem;
}

.left-container {
  flex: 1;
}

.right-container {
  flex: 1;

  fd-timeline fd-timeline-node fd-timeline-node-header fd-timeline-node-header-info span {
    font-size: 18px;
  }

  fd-timeline-node-body {
    text-align: justify;
  }

  fd-timeline-node-body span {
    font-size: 13px;
  }
}

.item-wrapper {
  display: $display-flex;
  flex-direction: $flex-column;
  width: $width;
}

// employee edit css
::ng-deep.fd-tabs__content {
  overflow-y: unset !important;
}

#tabCard {
  margin-bottom: 9px;
}

#tabcard .fd-container {
  padding: 10px 1.5rem;
}

.fd-tabs__process-icon {
  display: none !important;
}

/*sales pos  tab bar  */
nav {
  // box-shadow: 0 2px 5px rgba(94, 91, 91, 0.3);
  // border-radius: 2px;
  // background-color: #fff;
  border: 1px solid white;
}

nav.overflow {
  position: relative;
  padding: 0 24px;
}

ul.tabs {
  padding: 0;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: none;
}

ul.tabs li {
  display: inline-block;
  padding: 5px;
  font-size: 15px;
}

ul.tabs li .tab_title {
  display: block;
  text-align: center;
  text-decoration: none;
  color: rgb(46, 37, 37);
  padding: 3px 4px;
  cursor: pointer;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item {
  display: flex;
  padding: 1rem;
}

.button_cart {
  border: none;
  width: 21%;
  background-color: white;
  color: black;
  font-weight: bold;
}

.sales-img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

// array datepicker
.array .dualCalender {
  width: 0px !important;
}

// ---------------creaete lead css opened----------------------//
#sidebar {
  min-width: 180px;
  max-width: 200px;
  font-size: 1.3rem;
  overflow-y: visible;
  padding: 4px 0 0 10px;
}

ul.sc_list {
  list-style-type: none;
  margin: 0;
  padding: 0 8px 0 0;
}

ul.sc_list li.active * {
  color: #1976d2;
  background: #faf9f8;
}

ul.sc_list li.active a:before {
  border-radius: 50%;
  content: "";
  height: 6px;
  left: -2.5px;
  position: absolute;
  top: calc(9px + 12px - 3px);
  top: calc(9px + 1.2rem - 3px);
  width: 6px;
}

ul.sc_list li {
  box-sizing: border-box;
  line-height: 2.6rem;
  padding: 5px 0 4px 20px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

ul.sc_list li.h1 {
  margin-bottom: 0;
}

ul.sc_list li:before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  border-left-width: 1px;
  border-left-style: solid;
}

ul.sc_list:not(.embedded) li:before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  border-left-width: 1px;
  border-left-style: solid;
}

ul.sc_list:not(.embedded) li:before {
  border-left-color: #dbdbdb;
}

ul.sc_list li.h1:after {
  content: "";
  display: block;
  height: 1px;
  width: 40%;
  margin: 7px 0 4px;
  clear: both;
}

ul.sc_list li.active a:before {
  background: #1976d2;
}

ul.sc_list li.active a:before {
  border-radius: 50%;
  content: "";
  height: 6px;
  left: -2.5px;
  position: absolute;
  top: calc(9px + 12px - 3px);
  top: calc(9px + 1.2rem - 3px);
  width: 6px;
}

ul.sc_list li a {
  overflow: visible;
  font-size: 1.2rem;
  line-height: inherit;
  display: table-cell;
}

// ---------------creaete lead css closed----------------------//
.productlist {
  list-style: none;
  font-size: 15px;
  margin-left: -26px;
}

.call-button .fd-button {
  display: none !important;
}

// timeline ko lagi haleko start here ------------------------

.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
}

@media (min-width: 768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: "";
    display: block;
    border-top: 0.25rem dotted #3b82f6;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    top: 0.3125rem;
  }
  .timeline-steps .timeline-step:not(:first-child):before {
    content: "";
    display: block;
    border-top: 0.25rem dotted #3b82f6;
    width: 3.8125rem;
    position: absolute;
    right: 7.5rem;
    top: 0.3125rem;
  }
}

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6;
}

.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #3b82f6;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: 0.5;
}

// timeline ko lagi haleko end here--------------------

// this is for partywise report hai open here
.tables td {
  text-align: center !important;
  padding: 0px 0px !important;
}

.trtable th {
  padding: 0px 5px !important;
}

.filterpartywise {
  background: #f8fafb !important;
  border: 1px solid #8080805c !important;
}

// this is for partyise report hai end here

//do not touch account ledger report multi share button start
.btn-multi {
  position: relative;
  top: -35px;
  right: -17px;
}

.btn-multi .btn {
  display: inline-flex;

  font-family: "Roboto", sans-serif;

  text-decoration: none;

  justify-content: center;

  align-items: center;

  text-align: center;

  cursor: pointer;

  white-space: nowrap;

  padding: 8px 20px;

  font-size: 14px;

  background-color: #2787cb;

  border-radius: 2px;

  color: white;

  text-transform: uppercase;

  border: none;

  transition: all 0.2s ease-in;

  background-position: 50%;

  outline: none !important;

  &.btn-circle {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 0 !important;
    font-size: 18px;
  }

  i {
    font-size: 0.8rem !important;
    color: white !important;
  }
}

.btn-multi {
  input {
    display: none;

    &:not(:checked) {
      ~ label {
        transform: rotate(0);

        .btn {
          border-radius: 0;

          height: 19px;

          width: 18px;

          box-shadow: none;

          &:first-child {
            border-top-left-radius: 100%;
            width: 20px;
            transform: translate(35px, 25px);
          }

          &:nth-child(2) {
            border-top-right-radius: 100%;

            transform: translate(49px, 25px);
          }

          &:nth-child(3) {
            width: 19px;
            border-bottom-left-radius: 100%;

            transform: translate(30px, 44px);
          }

          &:nth-child(4) {
            border-bottom-right-radius: 100%;

            transform: translate(49px, 44px);
          }

          .icon {
            opacity: 0;
          }
        }

        > .icon {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }

  label {
    position: relative;

    // height: 110px;

    // width: 110px;

    display: block;

    transform: rotate(45deg);

    transition: all 0.2s ease-in;

    cursor: default;

    border-radius: 30px;

    .btn {
      position: absolute;

      // right:0px;

      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      &:nth-child(2) {
        transform: translate(60px, 0);
      }

      &:nth-child(3) {
        transform: translate(0, 60px);
      }

      &:nth-child(4) {
        transform: translate(60px, 60px);
      }

      .icon {
        opacity: 1;

        transition: all 0.15s ease-in-out;

        transform: rotate(-45deg);
      }
    }

    > .icon {
      color: white;

      position: absolute;

      border-radius: 50%;

      display: flex;

      font-size: 16px;

      align-items: center;

      justify-content: center;

      height: 60px;

      width: 60px;

      top: 15px;

      left: 22px;

      opacity: 0;

      transition: all 0.25s ease-in-out;
    }

    .icon {
      -webkit-user-select: none;

      -moz-user-select: none;

      -ms-user-select: none;
    }
  }
}

//do not touch account ledger report multi share button end

// input type time css
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: brightness(0) saturate(100%) invert(21%) sepia(20%) saturate(7193%) hue-rotate(228deg) brightness(74%) contrast(153%);
}

// widget-member css
.widget-member-relative {
  position: relative;
  left: 8px;
}

// widget-regional css
.widget-regional-relative {
  position: relative;
  left: 20px;
}

// key event csss
/* From cssbuttons.io */
.key-btn {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  touch-action: manipulation;
}

/* .key-shadow {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 border-radius: 12px;
 background: hsl(0deg 0% 0% / 0.25);
 will-change: transform;
 transform: translateY(2px);
 transition: transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
} */

.key-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  border-radius: 4px;
  background: linear-gradient(to left, rgb(95, 95, 95) 0%, rgb(153, 153, 153) 8%, rgb(221, 221, 221) 92%, rgb(252, 252, 252) 100%);
}

.key-front {
  display: block;
  position: relative;
  padding: 3px 14px;
  border-radius: 4px;
  font-size: 12px;
  color: rgb(0, 0, 0);
  background: rgb(243, 243, 243);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.key-btn:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.key-btn:hover .key-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.key-btn:active .key-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.key-btn:focus:not(:focus-visible) {
  outline: none;
}

.row2 {
  display: flex;
  flex-wrap: wrap;
  margin-right: 2.5px;
  margin-left: -7.5px;
}

.key-plus {
  position: relative;
  margin-left: 6px;
  margin-right: 6px;
  font-weight: bold;
  font-size: 16px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
  text-align: left;
}

.position-item {
  position: relative;
  margin-left: 117px;
}

.key-position {
  position: relative;
  bottom: 12px;
  margin-left: 88px;
}

.key-leval {
  position: relative;
  font-size: 14px;
}

// key value list item css
.key-value-items-list {
  height: auto;
  overflow-y: auto;
  background-color: #e5e5e5;
  padding: 5px;
  resize: vertical;
}

.key-value-items-list .alert-text {
  overflow: auto;
}

/*============================
subheader csss
=============================*/
.default-header {
  margin-top: -51px;
}

#title {
  position: relative;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #1b2e82, #228daf, #1c3d8a);
  // background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 5s ease-in;
  animation-duration: 4s;
  animation-iteration-count: 2;
  animation-direction: alternate-reverse;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.subheader-header {
  background-color: #fff;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  max-height: 126px;
  margin-top: -53px
}

@media only screen and (max-width: 360px) {
  .fd-title {
    font-size: 0.6rem !important;
  }
}

@media (min-width: 360.1px) and (max-width: 768px) {
  .fd-title {
    font-size: 1rem !important;
  }
}

.fd-title {
  white-space: break-spaces !important;
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

// add note responsive dialog box css
.fd-dialog__content {
  width: max-content;
  position: absolute !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: 0 !important;
  right: 0 !important;
}

/**********************************CSS FOR THE DEAL TAB **********************************/
.ribbon {
  background: #e9f6ff;
  height: 31px;
  width: auto;
  display: inline-block;
  padding: 0 40px 0 0;
  margin: auto;
  position: relative;
  color: black;
  line-height: 8px;
  font-size: 36px;
  text-align: center;
  margin-left: 2px;

  &:after {
    content: "";
    width: 18px;
    height: 24px;
    position: absolute;
    background: inherit;
    border-top: 1px solid #97b3d4;
    border-right: 1px solid #97b3d4;
    transform: rotate(45deg) skew(15deg, 15deg);
    right: -8px;
    top: 6px;
    z-index: 9;
  }
}

// p-dropdown used in languages component css
.p-dropdown-items-wrapper {
  background-color: white;
}

.p-datatable-table {
  width: 100% !important;
}

// for the account group delete and edit button
.faded-color {
  color: #b1aaa9 !important;
}

.position-absolute-right {
  position: absolute !important;
  right: -14px !important;
}

.border-radius {
  border: 1px solid black;
  border-radius: 15px;
  padding: 3px;
  position: relative;
  left: 4px;
}

// marksheet component table css
.marksheetTF {
  position: absolute;
  left: 20px;
  top: 18%;
}

.marksheetPF {
  position: absolute;
  right: 17px;
  top: 18%;
}

// css of monthy fee mapping
.inputPropertyRemover {
  border: none !important;
  outline: none !important;
  margin-top: 10px !important;
}

.theme2_main_div {
  margin-top: 56px;
}


// topbar css theme-2 start

.theme2Width {
  margin: 0px 15px;
}

.theme2-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.shadow-box {
  box-shadow: 0px 0px 1px 0px;
}

.suktas-row .fd-row {
  align-items: center !important;
}

@media (max-width: 991px) {
  #kt_header {
    height: 0px;
  }
}

@media (min-width: 1245px) {
  .show-mobile {
    display: none;
  }
}

@media (max-width: 1245px) and (min-width: 991px) {
  .show-desktop {
    display: none;
  }
  .theme2-margin {
    margin-top: 40px;
    background-color: white;
  }
}

// topbar css theme-2 start

// student profile css start
.sp_card {
  // position: relative;
  // top: -20px;
  // width: 400px;
  // height: 400px;
  // padding: 20px;
  // box-shadow: 3px 10px 20px rgb(0 0 0 / 20%);
  // border-radius: 3px;
  // border: 0;
  // margin-left: 30px;
  .sp_circle {
    border-radius: 3px;
    width: 150px;
    height: 150px;
    background: black;
    position: absolute;
    right: 0px;
    top: 0;
    background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
    border-bottom-left-radius: 170px;
  }

  .sp_content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 0;
  }

  h2 {
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 300;
  }

  .sp_detail {
    margin-bottom: 5px;

    a {
      text-decoration: none !important;
      color: black;
      margin-left: 8px;
      font-weight: 300;

      i {
        font-weight: 400;
      }
    }

    span {
      margin-left: 8px;;
    }
  }

  .location {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    i {
      color: red;
    }

    p {
      font-weight: 300;
    }
  }

  .sp_unset {
    position: unset !important;
  }
}


.cross_button {
  position: relative;
  bottom: 42px;
}

// student profile css end

.field {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: rgb(192, 192, 192);
  border-image: initial;
  margin-bottom: 5px;
}

.legend {
  width: inherit;
}


