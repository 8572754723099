/*----------------------------------------------------
@Components Custom Styles
Table of Content:
01/ Student Profile
02/ Question Answer
03/ Quiz Result
04/ Academic Dashboard
05/ View Timeline
06/ Assignment Dashboard
---------------------------------------------------------*/


/*01=================student profile css start=================================*/
.student-profile {
  .profile {
    background-image: linear-gradient(to right top, #476693, #007aa1, #008b8d, #00965b, #709816);
    height: 10rem;
  }

  .profile-intro {
    position: relative;
    z-index: 1;
    margin-top: -5rem;

    .profile-info {
      .circle {
        width: 100px;
        background: lightgray;
        height: 100px;
        border-radius: 50%;
        border: 6px solid white;
      }

      .profile-details {
        .profile-details-icon {
          a {
            color: darkblue;
          }
        }
      }
    }
  }

  /* Style the tab */
  .tab {
    overflow: hidden;
    border-bottom: 1px solid #ccc;

    /* Style the buttons inside the tab */
    button {
      background-color: inherit;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 14px 16px;
      font-size: 17px;

      &.active {
        color: darkblue;
        border-bottom: 2px solid darkblue;
      }
    }
  }

  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border-top: none;

    .box {
      height: 1rem;
      width: 1rem;
      margin-right: 5px;
    }

    .card {
      .cross {
        position: absolute;
        right: 0;
        top: 6px;
        cursor: pointer;
      }
    }

    .paid {
      background-color: #396c39
    }

    .unpaid {
      background-color: #d14343;
    }

    .unassigned {
      background-color: #077ca2;
    }
  }
}

/*=================student profile css end=================================*/

/*02=================Quiz Answer css start=================================*/
.question-answer {
  .card {
    .timer {
      strong {
        font-size: 1.4rem;
      }
    }
  }

  .questions {
    .card {
      .tot-ans {
        color: green;
        font-size: 1.4rem;
      }
    }
  }

  .answers {
    div {
      background-color: #fff;
    }
  }
}

/*=================Quiz Answer css end=================================*/


/*03=================Quiz Result css start=================================*/
.quiz-result {
  h3 {
    font-weight: 800;
    line-height: 2.4em;
    font-size: 2rem
  }

  p {
    font-size: 1.5rem
  }
}

/*=================Quiz Result css end=================================*/


/*04=================Academic Dashboard css start=================================*/
.events {
  scroll-snap-type: x mandatory;

  .event-card {
    background-color: rgb(233, 238, 245);
    width: 250px;
    min-width: 250px;
    padding: 40px 20px;
    margin-bottom: 10px;

    .event-date {
      font-size: 2.3rem;
      font-weight: 800;
    }

    .event-details {
      .event-name {
        font-size: 1.8rem;
        font-weight: 700;
      }
    }
  }

  .event-card:nth-child(1) {
    background-color: #007aa1;
    color: #fff;
  }


}

/*=================Academic Dashboard css end=================================*/


/*01=================View Timeline css start=================================*/
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 50%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  background-color: #999999;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline-badge.success {
  background-color: #3f903f !important;
}

.timeline-badge.warning {
  background-color: #f0ad4e !important;
}

.timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

/*=================View Timeline css end=================================*/


/*06=================Assignment Dashboard css end=================================*/
.assignment-dashboard {
  margin-top: -20px;

  .card:hover {
    transform: translateY(-3px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .theaderr {
    background-color: rgba(130, 189, 211, 0.658);
    // tr{
    // color:$bg-white;
    // }
  }

  .remainday {
    .circle {
      background: #cf4e4a;
      border-radius: 200px;
      color: white;
      height: 200px;
      font-weight: bold;
      font-size: 16px;
      width: 200px;
      display: table;
      margin: 20px auto;

      p {
        vertical-align: middle;
        display: table-cell;
        color: aliceblue;
        text-align: center;
      }


    }
  }

  .questions {
    .question-card {
      background-color: rgb(233, 238, 245);

      .subject-name {
        font-size: 1.3rem;
        font-weight: 700;
      }


    }
  }
}

/*=================Assignment Dashboard css end=================================*/

