.card {
  position: $position-relative;
  display: $display-flex;
  flex-direction: $flex-column;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-color: $bg-white;
  background-clip: border-box !important;
  border: 1px solid #EBEDF3 !important;
  border-radius: 0.42rem !important;


  //Body
  &-body {
    flex: $flex-auto;
    min-height: 1px !important;
    padding: 2.25rem !important;;
  }


}

.card-array {
  padding: 0 10px;
  transition: all ease 0.4s;
}

.card-array:hover {
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.301);
}


.gutter-b {
  margin-bottom: 25px !important;
}


// uplod file css
.card-upload {
  margin-bottom: 19px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
}

.expand-upload {
  margin-bottom: 10px;
}

.image-upload > input {
  display: none;
}

.image-upload {
  padding: 30px;
}

.upload-icon {
  font-size: 4rem;
}

.open_button {
  background-color: #2787cb;
  color: white;
  border: white;
  width: 45px;


}


// card note header (crm call css)
.note-header {
  display: $flex;
  padding: 10px 20px;
  flex-wrap: $flex-wrap;
  align-items: $center;
  justify-content: $space-between;
}

.note-content {
  padding: 0 20px;
}


