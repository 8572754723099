// font famnily
$primary-font: 'Metric',
sans-serif;


// colors
$primary-color: #0a6ed1;
$secondary-color: #45b39d;

$bg-white: #ffffff !important;
$bg-gray: #f8fafb !important;
$color-black: #3F4254;
$color-red: #e74c3c;
$color-blue: #3699ff;
$color-dark-red: #dc3545;
$red-light: #ffebeb;
$orange-dark: #e1710e;
$orange-light: #fef7f1;
$green-dark: #13793e;
$green-light: #f1fdf6;
$light-gray: #f4f4f4;
$dark-gray: #6a6d70;
$deep-gray: #faf9f8;
$chrome-yellow: #FFA800;
$color-grey: grey;


$f2f4f8: #f2f4f8;
/*     brand Colors              */
$brand-primary: $primary-color !default;
$brand-info: $secondary-color !default;
$brand-success: $green-dark !default;
$brand-warning: $orange-dark !default;
$brand-danger: $color-red !default;
$brand-default: $dark-gray !default;

// font
$fs-primary: 17px !default;
$fs-lg: 24px !default;
$fs-xl: $fs-primary * 2 !default;
$fs-md: 19px !default;
$fs-sm: 13px !default;
$fs-xs: 11px !default;

// font weight
$fw-primary: 300 !default;
$fw-sm: 400 !default;
$fw-md: 500 !default;
$fw-xs: 100 !default;
$fw-xl: 900 !default;
$fw-lg: 700 !default;

// line-height
$lh-primary: 1.5;
$lh-sm: 1.2;
$lh-md: 1.7;
$lh-xs: 1;
$lh-xl: 2;

$shadow-opacity: 0.2 !default;
$penumbra-opacity: 0.14 !default;
$ambient-opacity: 0.12 !default;

// Declare the following for reuse with both mixins and the bootstrap variables
$box-shadow-focus: 0 0 8px rgba($color-black, .18),
0 8px 16px rgba($color-black, .36);

$bs-primary: 0 2px 2px 0 rgba($color-black, $shadow-opacity),
0 3px 1px -2px rgba($color-black, $penumbra-opacity),
0 1px 5px 0 rgba($color-black, $ambient-opacity);


//float
$float-right: right;
$float-left: left;


// overflow
$overflow-auto: auto !important;
$overflow-scroll: scroll !important;
$overflow-hidden: hidden !important;
$overflow-clip: clip;
$overflow-visible: visible;


// position
$position-static: static !important;
$position-relative: relative !important;
$position-absolute: absolute !important;
$position-fixed: fixed !important;
$position-sticky: sticky !important;


// display
$display-block: block;
$display-inline: inline;
$display-inline-block: inline-block;
$display-flex: flex !important;
$display-inline-flex: inline-flex;

// flex direction
$flex-row: row !important;
$flex-row-reverse: row-reverse;
$flex-column: column !important;
$flex-column-reverse: column-reverse;
$flex-auto: 1 1 auto !important;
$flex-end: flex-end;
$flex-start: flex-start;


// flex values
$center: center !important;
$align-left: left !important;
$space-between: space-between !important;
$space-around: space-around !important;
$flex-start: flex-start !important;
$flex-end: flex-end !important;
$flex-wrap: wrap !important;

// display values
$flex: flex !important;
$block: block;
$inline-block: inline-block;
$inline: inline;
$display-none: none;

// border
$border-box: border-box;

// standard width
$width: 100%;
$widthpx: 200px;
$widthVw: 100vw;

// standard height
$heightVh: 100vh;
$height: 300px;

$zero: 0 !important;
