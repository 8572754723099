//---------------------------------------------- min-width opened------------------------------------------//
@media (min-width: 768px) {
  .select-flex {
    display: $display-flex;
    flex-direction: $flex-row;
  }

  .select-flex label {
    position: relative;
    overflow: inherit;
    display: inline-block;
    width: 216px;
    text-align: left;
  }

  .form-item {
    display: $display-flex;
    flex-direction: $flex-row;
  }
}

@media (min-width: 961px) {
  .tenant-relative {
    position: relative;
  }
  .tenant-pos-center {
    position: $position-absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: $center;
    top: 0;
  }
}


@media (min-width: 991px) {
  .suktas-subheader {
    margin-top: -60px !important;
  }
}

//---------------------------------------------- min-width closed------------------------------------------//
//---------------------------------------------------- max-width opened--------------------------------//
//for galaxy fold
@media only screen and (max-width: 360px) {
  .form-title {
    padding: 0 3rem;
  }

  .style-this,
  h5 {
    width: 300px !important;
  }

  .row {
    display: $flex !important;
    flex-direction: $flex-column !important;
  }

  .fold {
    margin: 7px 0 !important;
    min-width: $width !important;
  }

  .fold-btn {
    margin-top: 5px !important;
  }

  .card-head {
    display: $flex;
    flex-direction: $flex-column;
    height: 12vh !important;
  }

  .fold-h2 {
    margin-right: -22px !important;
  }

  .fold-span {
    margin-top: 20px !important;
  }

  // contra master
  .fold-tableHeader {
    display: $flex;
    flex-direction: $flex-column;
    height: auto !important;
  }

  .fold-mb {
    margin-bottom: 10px !important;
  }

  .fold-title {
    font-size: 12px !important;
  }

  // for the subheader title for report
  .mob_fold {
    font-size: 12px !important;
  }

  .fold-col {
    flex-direction: $flex-column;
    gap: 10px;
  }

  // suktas paginator css
  .paginator-fold {
    display: $flex;
    flex-direction: $flex-column;

  }

  .fold-end {
    justify-content: $flex-end;
  }

  .fd-title {
    font-size: 0.6rem !important;
  }

  .fold__btn {
    height: 50px !important;
    max-height: 50px !important;
  }

  .fold-card {
    max-width: 182px;
  }

  .fold-container {
    padding: 0 2px !important;
  }
}

// for voucher type
@media only screen and (max-width: 520px) {
  .vouchertypeformlabel {
    display: flex !important;
    flex-wrap: wrap !important;

    label {
      width: 100% !important;
    }
  }

  .resp-btn {
    min-width: -webkit-fill-available !important;
    margin-top: 5px !important;
  }
}

@media only screen and (max-width: 600px) {
  .fs-md {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 666px) {
  .dialog-body {
    width: $width !important;
  }

  .dialog-btn {
    float: $float-right;
    margin-bottom: 10px !important;
  }

  .imei-btn {
    display: $flex;
    justify-content: $flex-end;
  }

  // #save,#del,#cancel{
  //     margin-bottom: 5px !important;
  // }
}

// stock journal
@media only screen and (max-width: 760px) {
  .stock-journal-tr:nth-child(odd) {
    background: #e8f3ff;
  }
}

// create lead media query
@media (max-width: 767.98px) {
  #sidebar {
    min-width: 100%;
    max-width: 180px;
    border-left: none;
  }

  .filename {
    // display: $flex;
    // justify-content: $flex-end;
    width: 70px;
    max-width: 70px;
  }
}


@media only screen and (max-width: 768px) {
  .form-item {
    display: $display-flex;
    flex-direction: $flex-column;
  }


  .image-view {
    width: 100% !important;
  }

  .detail-title mt-2 mb-2 fs-15px {
    font-size: $fs-sm;
  }

  // for voucher type
  .desktopvouchertype {
    display: none;
  }

  .fd-title {
    font-size: 1rem !important;
  }
  #sidebar {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .select-flex {
    display: flex !important;
    flex-direction: column !important;
  }

  .logo {
    width: 100% !important;
    height: 100% !important;
  }

  .height-80vh {
    height: 80vh;
  }

  .side_date .dualCalender {
    left: -30px !important;
  }

  // for subheader add button
  .subheader_add {
    min-width: 8.66667% !important;
  }
}

// minimize garda subheader fix hune
@media (max-width: 991px) {
  .suktas-subheader {
    margin-top: 0px !important;
  }
}

@media (max-width: 992px) {
  .assignment-dashboard {
    margin-top: -4px !important;
  }
}


@media screen and (max-width: 1100px) {
  .logo {
    width: 100% !important;
    height: 100% !important;
  }
}

//---------------------------------------------------- max-width closed--------------------------------//
//----------------min and max-width opened----------------------------------------//
@media (min-width: 320px) and (max-width: 480px) {

  /* CSS */
  #voucherNo,
  #transportationCompany {
    margin-top: 4%;
  }

  .delivery-card {
    padding: 0 20px !important;
  }

  .form-title h3 {
    font-size: 14px !important;
    width: 200px;
  }

  /*=================
==================
mobile */
  .form-title {
    padding: 11px !important;
  }

  .form-title h3 {
    font-size: 18px;
  }

  .card.card-custom > .card-body {
    padding: 10px !important;
  }
}

@media (min-width: 125px) and (max-width: 600px) {
  .trial_balance {

    th,
    td {
      font-size: 13px;
    }
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  .filename {
    width: 135px;
    max-width: 135px;
  }

}


//----------------min and max-width closed----------------------------------------//
