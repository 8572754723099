html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: $primary-font;
  font-weight: $fw-primary;
  line-height: $lh-primary;
}

h1,
.h1 {
  font-size: $fs-xl;
  line-height: 1.15em;
}

h2,
.h2 {
  font-size: $fs-lg;
}

h3,
.h3 {
  font-size: $fs-md;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4,
.h4 {
  font-size: $fs-primary;
  line-height: 1.4em;
  font-weight: 300;
}

h5,
.h5 {
  font-size: $fs-sm;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: $fs-xs;
  text-transform: uppercase;
  font-weight: $fw-xl;
}

.pf {
  font-family: $primary-font;
}

.text-warning {
  color: $brand-warning !important;
}

.text-primary {
  color: $brand-primary !important;
}

.text-danger {
  color: $brand-danger !important;
}

.text-success {
  color: $brand-success !important;
}

.text-info {
  color: $brand-info !important;
}

.text-default {
  color: $brand-default !important;
}


// color
.color-white {
  color: $bg-white !important;
}

// overflow
.overflow-auto {
  overflow: $overflow-auto;
}

.overflow-x-auto {
  overflow-x: $overflow-auto;

}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-y-auto {
  overflow-y: $overflow-auto;
}

.overflow-y-scroll {
  overflow-y: $overflow-scroll;
}

.overflow-x-hidden {
  overflow-x: $overflow-hidden;
}


// width %
.width-auto {
  width: auto !important;
}

.width-5 {
  width: calc($width - 95%);
}


.width-6 {
  width: calc($width - 94%);
}

.width-7 {
  width: calc($width - 93%);
}


.width-10 {
  width: calc($width - 90%);
}

.width-11 {
  width: calc($width - 89%);
}


.width-14 {
  width: calc($width - 86%);
}

.width-15 {
  width: calc($width - 85%);
}

.width-16 {
  width: 16%;
}


.width-20 {
  width: calc($width - 80%);
}

.width-22px {
  width: 22px;
}


.width-25 {
  width: calc($width - 75%);
}

.checkbox {
  // left: 30%;
  top: 6%
}

.width-40 {
  width: calc($width - 60%);
}

.width-45 {
  width: calc($width - 55%);
}

.width-30 {
  width: calc($width - 70%);
}

.width-60 {
  width: calc($width - 40%);
}

.width-65 {
  width: calc($width - 35%);
}

.width-36px {
  width: 36px;
}


.width-50 {
  width: calc($width - 50%) !important;
}

.width-54 {
  width: calc($width - 46%) !important;
}

.width-70 {
  width: calc($width - 30%);
}

.width-100 {
  width: $width !important;
}

.width-88px {
  width: 88px;
}

// width px
.width-0 {
  width: 0 !important;
}

.width-10px {
  width: calc($widthpx - 190px);
}

.width-16px {
  width: calc($widthpx - 184px);
}

.width-23px {
  width: calc($widthpx - 177px);
}

.width-29px {
  width: 29px;
}

.width-32px {
  width: calc($widthpx - 168px);
}

.width-39px {
  width: calc($widthpx - 161px);
}

.width-40px {
  width: calc($widthpx - 160px);
}

.width-42px {
  width: 42px;
}

.width-44px {
  width: calc($widthpx - 156px);
}

.width-47px {
  width: calc($widthpx - 153px);
}

.width-50px {
  width: calc($widthpx - 150px);
}


.width-60px {
  width: calc($widthpx - 140px);
}

.width-67px {
  width: calc($widthpx - 133px);
}

.width-70px {
  width: calc($widthpx - 130px);
}

.width-72px {
  width: 72px;
}


.width-75px {
  width: calc($widthpx - 125px);
}

.width-80px {
  width: calc($widthpx - 120px);
}

.width-88px {
  width: 88px;
}

.width-90px {
  width: calc($widthpx - 110px);
}

.width-90 {
  width: 90%;
}

.width-96 {
  width: 96%
}

.width-100px {
  width: calc($widthpx - 100px);
}

.width-110px {
  width: calc($widthpx - 90px);
}

.width-115px {
  width: calc($widthpx - 85px);
}

.width-118px {
  width: calc($widthpx - 82px);
}

.width-120px {
  width: calc($widthpx - 80px);
}

.width-130px {
  width: calc($widthpx - 70px);
}

.width-135px {
  width: calc($widthpx - 65px);

}

.width-140px {
  width: calc($widthpx - 60px);
}

.width-150px {
  width: calc($widthpx - 50px);
}

.width-170px {
  width: calc($widthpx - 30px);
}

.width-175px {
  width: 175px !important;
}


.width-180px {
  width: calc($widthpx - 20px);
}

.width-190px {
  width: calc($widthpx - 10px);
}

.width-200px {
  width: $widthpx;
}

.width-210px {
  width: calc($widthpx + 10px);

}

.width-216px {
  width: 216px !important;
}

.width-220px {
  width: calc($widthpx + 20px);
}

.width-193px {
  width: calc($widthpx + 7px);
}

.width-240px {
  width: calc($widthpx + 40px);
}


.width-250px {
  width: calc($widthpx + 50px) !important;
}

.width-270px {
  width: calc($widthpx + 70px);
}

.width-280px {
  width: calc($widthpx + 80px);
}

.width-300px {
  width: calc($widthpx + 100px);
}

.width-355px {
  width: calc($widthpx + 155px);
}

.width-362px {
  width: 362px;
}

.width-380px {
  width: calc($widthpx + 180px);
}

.width-400px {
  width: calc($widthpx + 200px);
}

.width-500px {
  width: calc($widthpx + 300px);
}

.width-600px {
  width: calc($widthpx + 400px);
}

.width-650px {
  width: calc($widthpx + 450px);
}

// width in vw
.width-40vw {
  width: $widthVw - 60vw;
}

.width-50vw {
  width: $widthVw - 50vw;
}

.width-100vw {
  width: $widthVw;
}

// height in px
.height-25px {
  height: 25px;
}

// height in vh
.height-15vh {
  height: $heightVh - 85vh;
}

.height-40vh {
  height: $heightVh - 60vh;
}

.height-70vh {
  height: $heightVh - 30vh;
}

.height-88vh {
  height: $heightVh - 12vh;
}

.height-100vh {
  height: $heightVh;
}

.height-100 {
  height: 100% !important;
}

.height-100vh-minus350px {
  height: calc(100vh - 350px);
}

.height-60 {
  height: 60%;
}


// display
.display-block {
  display: block;
}

.display-flex {
  display: $display-flex;
  flex-direction: $flex-row;
}

.display-none {
  display: none !important;
}

.flexdirection-row {
  flex-direction: $flex-row;
}


.justify-content-center {
  justify-content: $center;
}

.space-between {
  justify-content: $space-between;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.flexH-end {
  display: $display-flex;
  justify-content: $flex-end;
}


.info-header {
  font-weight: bold;
  font-size: calc($fs-md - 3px);
  text-transform: uppercase;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
}

.right-padding-10rem {
  padding-right: 10rem;
}

.padding-1rem {
  padding: 1rem !important
}

.padding-0 {
  padding: 0.5rem !important;
}

.padding-1px {
  padding: 1px !important;
}

.padding-point25rem {
  padding: 0.25rem !important;
}

.padding-5px {
  padding: 5px !important;
}

.padding-tb {
  padding: 1rem 1rem !important;
}

.padding-left-55px {
  padding-left: 55px !important;
}

.py-5px {
  padding: 0 5px !important;
}

.py-10px {
  padding: 0 10px !important;
}

.py-22px {
  padding: 0 22px !important;
}

.pl-20px {
  padding-left: 20px !important;
}

//font-size
.fs-sm {
  font-size: $fs-sm !important;
}

.fs-14px {
  font-size: calc($fs-sm + 1px);
}

.fs-primary {
  font-size: $fs-primary !important;
}

.fs-md {
  font-size: $fs-md !important;
}

.fs-xs {
  font-size: $fs-xs !important;
}

.fs-lg {
  font-size: $fs-lg !important;
}

.fs-xl {
  font-size: $fs-xl !important;
}

.fs-12px {
  font-size: calc($fs-primary - 5px) !important;
}

.fs-13px {
  font-size: calc($fs-primary - 4px) !important;
}

.fs-14px {
  font-size: calc($fs-primary - 3px) !important;
}

.fs-15px {
  font-size: calc($fs-primary - 2px) !important;
}

.fs-16px {
  font-size: calc($fs-primary - 1px);
}

.fs-17px {
  font-size: 17px;
}

// font-size->em
.fs-point82em {
  font-size: 0.82em
}

.fs-14px {
  font-size: 14px !important;
}

// font-size->rem
.fs-1point5rem {
  font-size: 1.5rem;
}


// fontweight
.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-900 {
  font-weight: 900 !important;
}

// opacity
.opacity-point5 {
  opacity: 0.5;
}

// margin
.mt-point5rem {
  margin-top: 0.5rem;
}

.mt-minus40px {
  margin-top: -40px !important
}

.mt-4px {
  margin-top: 4px !important;
}

.mt-2px {
  margin-top: 2px;
}


.mt-5px {
  margin-top: 5px !important;
}

.mt-6px {
  margin-top: 6px !important;
}

.mt-8px {
  margin-top: 8px !important;
}

.mt-9px {
  margin-top: 9px !important;
}

.mt-11px {
  margin-top: 11px !important;
}

.mt-12px {
  margin-top: 12px !important
}

.mt-27px {
  margin-top: 27px !important;
}

.mt-60px {
  margin-top: 60px !important;
}

.mt-90px {
  margin-top: 90px !important;
}

.mt-6per {
  margin-top: 6% !important;
}

.my-20px {
  margin: 20px 0;
}

.mx-2px {
  margin: 0 2px;
}

.mx-16px {
  margin: 0 16px;
}

.m-5px {
  margin: 5px !important;
}

.mt-17px {
  margin-top: 17px;
}

.ml-21px {
  margin-left: 21px
}

.mt-minus3per {
  margin-top: -3% !important;
}

.mb-0px,
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-34px {
  margin-bottom: 34px !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-8px {
  margin-top: 8px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-minus30px {
  margin-top: -30px !important;
}


.mt-15px {
  margin-top: 15px;
}

.mt-30px {
  margin-top: 30px !important;
}

.mt-60px {
  margin-top: 60px !important;
}

.mt-80px {
  margin-top: 80px !important;
}

.mt-minus15px {
  margin-top: -15px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.margin-20-2 {
  margin: 20px 2px !important;
}

.mb-minus10px {
  margin-bottom: -10px !important;
}

.mt-minus5px {
  margin-top: -5px !important;
}

.mt-1px {
  margin-top: 1px !important;
}

.ml-2px {
  margin-left: 2px !important;
}

.ml-10px {
  margin-left: 10px;
}

.ml-12px {
  margin-left: 12px !important;
}

.ml-20px {
  margin-left: 20px !important;
}

.mb-80px {
  margin-bottom: 80px !important;
}

.margin-20-2 {
  margin: 20px 2px !important;
}

.mr-18px {
  margin-right: 18px !important;
}


.mr-20px {
  margin-right: 20px !important;
}

.mr-8px {
  margin-right: 8px !important;
}

.m-10px {
  margin: 10px !important;
}

.ml-minus35px {
  margin-left: -35px;
}

.ml-minus15px {
  margin-left: -15px;
}

.ml-10px {
  margin-left: 10px;
}

.mp-10px {
  margin: 10px !important;
  padding: 15px !important;
}

.mr-5px {
  margin-right: 5px !important;
}


.mt-21px {
  margin-top: 21px !important;
}

// margin minus
.mtminus-3px {
  margin-top: -3px !important;
}

.mtminus-5px {
  margin-top: -5px !important;
}

#mbminus-10px {
  margin-bottom: -10px !important;
}

// box-shadow
.bs-primary {
  box-shadow: $bs-primary;
}

//---------------- to center---------------------------------------------------//
// ********when flex direction is row*******************************//
.jusV-center {
  display: $display-flex;
  justify-content: $center;
  align-items: $center;
}

.flexH-center {
  display: $display-flex;
  justify-content: $center;
}

.flexV-center {
  display: $display-flex;
  align-items: $center;
}

.align-items-center {
  align-items: $center;
}

// .flex-content {
//     display: $display-flex;
//     justify-content: $center;
// }

.flex-wrap {
  flex-wrap: $flex-wrap;
}

.justify-content-end {
  display: $display-flex;
  justify-content: $flex-end;
}

// ********when flex direction is column*******************************//
.cFlexHV-center {
  display: $display-flex;
  flex-direction: $flex-column;
  justify-content: $center;
  align-items: $center;
}


.cFlexH-center {
  display: $display-flex;
  flex-direction: $flex-column;
  align-items: $center;
}

.cFlexV-center {
  display: $display-flex;
  flex-direction: $flex-column;
  justify-content: $center;
}

//---------------- to center end---------------------------------------------------//


.flex-center {
  display: $display-flex;
  justify-content: $center;
  align-items: $center;
}


// border
.border-bottom {
  border-bottom: 1px solid #f1f1f1;
}

.border-none {
  border: none;
}

.border-grey {
  border: 1px solid $color-grey !important;
}

.border-color-transparent {
  border-color: transparent !important;
}

.border-width-2px {
  border-width: 2px !important;
}

.border-style-dashed {
  border-style: dashed !important;
}

.border-color-007ad9 {
  border-color: #007ad9 !important;
}

.border-color-bbddf5 {
  border-color: #bbddf5;
}

// float
.float-right {
  float: right !important;
}

.flex-space-between {
  display: $display-flex;
  justify-content: $space-between;
}

// background color
.bg-gray {
  background-color: #e8f3ff;
}

.bg-lightgray {
  background: #f8fafb
}

.bg-deepgray {
  background-color: $deep-gray;
}

.bg-chormeYellow {
  background-color: $chrome-yellow !important;
}

.bg-3890cf {
  background-color: #3890cf
}

.bg-e8f3ff42 {
  background-color: #e8f3ff42 !important;
}

.bg-bbddf5 {
  background-color: #bbddf5;
}

// color
.color-white {
  color: $bg-white;
}

.color-gray {
  color: $color-grey;
}


// #0a6ed1
.color-blue {
  color: $primary-color;
}

.color-dark-red {
  color: $color-dark-red;
}

.color-bblue {
  color: $color-blue
}

.rgb-blue {
  color: rgb(10 110 209) !important;
}

.color-0078d4 {
  color: #0078d4;
}

// id-width
#width-40 {
  width: calc($width - 60%)
}


// cursor
.cursor-notallowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.top-2rem {
  top: 2.5rem;
}

// height
.height-78vh {
  height: 78vh !important;
}

.height-300px {
  height: $height;

}

.height-320px {
  height: calc($height + 20px);
}

.height-600px {
  height: calc($height + 300px);
}

.height-260px {
  height: calc($height - 40px);
}

.height-220px {
  height: calc($height - 80px);
}

.height-38px {
  height: 38px;
}

.height-50px {
  height: calc($height - 250px);
}

.height-32px {
  height: 32px;
}

.height-42px {
  height: calc($height - 258px);
}

// padding
.padding-0-point5rem {
  padding: 0 0.5rem !important;
}

.padding-point5-0rem {
  padding: 0.5rem 0 !important;
}

.position-relative-left-top {
  position: relative;
  left: 522px;
  top: -8px;
}

.padding-5px {
  padding: 5px !important;
}

#p-25px {
  padding: 25px !important;
}

.p-20px {
  padding: 20px;
}

.padding-0-5px {
  padding: 0 5px !important;
}

.padding-0-17-0-0 {
  padding: 0 17px 0 0 !important;
}

.pl-5px {
  padding-left: 5px !important;
}

.pl-10px {
  padding-left: 10px !important;
}

.pl-55px {
  padding-left: 55px !important;
}

.pt-10px {
  padding-top: 10px !important;
}

.pt-5px {
  padding-top: 5px !important;
}

.pb-5px {
  padding-bottom: 5px !important;
}

.pr-25px {
  padding-right: 25px !important;
}

.pb-150px {
  padding-bottom: 150px !important;
}

.pr-3point5px {
  padding-right: 3.5px !important;
}

.px-0 {
  padding: 0 0 !important;
}

.px-20px {
  padding: 0 20px !important;
}

.px-25px {
  padding: 0 25px !important;
}

#py-5px {
  padding: 5px 0 !important;
}

.p-5px {
  padding: 5px !important;
}

.p-8px {
  padding: 8px !important;
}

.pr-5px {
  padding-right: 5px !important;
}

.p-10px {
  padding: 10px !important;
}

.p-point5em {
  padding: 0.5em !important;
}

.content-end-padding {
  justify-content: end;
  padding: 0.5vw 1vw 0vw 1vw;
}


// positions
.position-sticky {
  position: sticky;
}

.position-relative {
  position: $position-relative;
}

.position-center {
  position: $position-absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: $center;
  top: 0;
}

.position-relative-0px {
  position: $position-relative;
  top: 0px;
}

.position-relative-b5px {
  position: $position-relative;
  bottom: 5px
}

.position-relative-left {
  position: $position-relative;
  left: 84px
}


// alignment
.vertical-align-middle {
  vertical-align: middle;
}

.text-center {
  text-align: $center !important;
}

.text-right {
  text-align: $float-right;
}

// Z-index
.zindex-0 {
  z-index: 0;
}


//margin-rem
.mt-1 {
  margin-top: 0.25rem !important;
}


.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}


.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.my-1 {
  margin: 0.25rem 0 !important;
}

.my-2 {
  margin: 0.5rem 0 !important;
}

.my-3 {
  margin: 0.75rem 0 !important;
}

.my-4 {
  margin: 1rem 0 !important;
}

.my-5 {
  margin: 1.25rem 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

// padding rem
.p-2 {
  padding: 0.5rem;
}

.pt-1rem {
  padding-top: 1rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem 0 !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.px-5 {
  padding: 0 1.25rem !important;
}

// subheader kolagi use gareko ho yo
// .subheader-fix{
//     margin-top: -51px;
// }


// colors
.customcolor1b2e82 {
  color: #1b2e82 !important;
}

.color-red {
  color: $color-red;
}

// uppercase
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}


.text-left {
  text-align: $float-left;
}


// gap
.gap-15px {
  gap: 15px !important;
}

.gap-5px {
  gap: 5px !important
}

.gap-20px {
  gap: 20px !important;
}

.gap-40px {
  gap: 40px;
}

.vatTable thead tr th:first-child,
.vatTable tbody tr td:first-child {
  width: calc($widthpx - 10px);
}


// FILTER-INVERT
.filter-invert {
  filter: invert(100%);
}


// max-height
.max-height-200px {
  max-height: 200px !important;
}


.max-height-300px {
  max-height: 300px !important;
}

// min-height
.min-height-40px {
  min-height: 40px !important;
}

.min-height-100px {
  min-height: 100px !important;
}

.min-height-260px {
  min-height: 260px !important;
}

.min-height-300px {
  min-height: 300px !important;
}

// min-width
.min-width-0 {
  min-width: 0;
}

.min-width-93 {
  min-width: 93% !important;
}
.min-width-120px {
  min-width: 120px!important;
}

.min-width-190px {
  min-width: 190px !important;
}
.min-width-150px {
  min-width: 150px !important;
}

.min-width-15px {
  min-width: 15px !important;
}

.min-width-80px {
  min-width: 80px !important
}

.min-width-50px {
  min-width: 50px !important
}

.min-width-51 {
  min-width: 51%;
}

.min-width-90px {
  min-width: 90px !important
}

.min-width-100px {
  min-width: 100px;
}

.min-width-190px {
  min-width: 190px !important;
}

.min-width-200px {
  min-width: 200px !important;
}

.min-width-270px {
  min-width: 270px;
}

.min-width-420px {
  min-width: 420px;
}

.min-width-540px {
  min-width: 540px;
}

// float
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}


// flex values
.flex-1 {
  flex: 1 !important;
}


// Outline
.outline-none {
  outline: none;
}
.cross{
  margin-top: 45px;
  margin-left: 15px;
}
.input-checkbox{
  height: 40px;
  width: 18px;
}

