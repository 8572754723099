.row {
  display: $display-flex;
  flex-wrap: $flex-wrap;
  margin: 0 -12.5px;
}

.container {
  display: $flex;
  flex-direction: $flex-column;
}
