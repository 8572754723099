/**********************VARIABLE START*****************/
$color-white: #ffffff;
$color-black: #373737;
$color-blue: #64acff;
$main-color: #ff6464;
$color-dark-blue: #0a6ed1;
$paragraph-color: #565e7c;
$body-font: Metric !important;
$body-color: #373e58;
$paragraph-font: Metric !important;
$head-color: #616161;

/**********************VARIABLE END*****************/
/**********************BASIC STYLE START*****************/
body {
  font-size: 16px;
  line-height: 1.8;
  background-color: white;
  font-family: $body-font;
}

a {
  display: inline-block;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

button {
  margin: 0;
  padding: 0;
  outline: 0;

  &:focus {
    outline: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $body-color;
  font-weight: 600;
  line-height: 1.3;
}

p {
  font-family: $paragraph-font;
  color: $paragraph-color;
  font-weight: 400;
}

img {
  max-width: 100%;
}

.d-block {
  display: block;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-94 {
  padding-top: 94px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-40 {
  padding-top: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-50 {
  margin-left: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-20 {
  margin-left: 20px;
}

.text-justify {
  text-align: justify;
}

.cursor-pointer {
  cursor: pointer;
}

.color-white {
  color: $color-white;
}

.bg-color-blue {
  background-color: #0a6ed1;
}

.box-shadow {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.border-radius {
  border-radius: 50px !important;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-top {
  border-radius: 15px 15px 0 0;
}

.border-bottom {
  border-bottom: 1px solid rgba(224, 220, 220, 0.4) !important;
}

.text-center {
  text-align: center;
}

.login_button {
  background-color: #7eb9fd !important;
}
.back-btn {
  background: #2a6df5;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  border-radius: 30px;
  display: inline-block;
  padding: 10px 38px;
  border: 2px solid #2a6df5;
  transition: all 0.5s ease 0s;
}

.back-btn:hover {
  background: none;
  color: #2a6df5;
}

.back-btn-border {
  background: #ffffff;
  border: 2px solid rgba(15, 22, 41, 0.078);
  font-size: 15px;
  font-weight: 600;
  border-radius: 30px;
  display: inline-block;
  padding: 10px 38px;
  transition: all 0.5s ease 0s;
  color: #020334;
}

.back-btn-border:hover {
  background: #2a6df5;
  color: #ffffff;
  border-color: #2a6df5;
}

/* ======================
 breadcrumb- bar design
 ========================*/
.breadcrumb-section {
  .breadcrumb-background-image-wraper {
    height: 260px;
    background-image: linear-gradient(to bottom, rgba(51, 109, 218, 0.52), rgba(5, 79, 218, 0.73)),
    url('../academysite/assests/breadcrumb-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .breadcrumb {
    align-items: center;
  }


  .gray-light-bg {
    background-color: rgba(239, 235, 237, 0.8);

  }

  .breadcrumb-item {

    display: flex;
  }

  .breadcrumb {
    li:nth-child(1) {
      margin-right: 8px;
    }

    .breadcrumb-item {
      display: flex;
      justify-content: center;
    }

    .aboutc {
      margin-left: 8px;
    }
  }

  .custom-breadcrumb ol li a,
  .custom-breadcrumb ol li.breadcrumb-item.active {
    opacity: 0.7;

    font-weight: 600;
  }

  a {
    color: #09277f;
    text-decoration: none;
    background-color: transparent;
  }
}

/*end of breadcrumb*/
/**********************TOP HEADER AREA CSS END*****************/
/**********************NAVBAR AREA CSS START*****************/
.main-nav {
  position: inherit;
  background: $color-white;
  top: 0;
  left: 0;
  padding: 0px 0;
  width: 100%;
  z-index: 999;
  position: fixed;
  border-bottom: 1px solid #ebebeb;

  nav {
    .navbar-nav {
      .nav-item {

        &:hover a,
        .active a {
          color: $main-color;
        }

        a {
          text-transform: capitalize;
          color: $color-black;
          font-weight: 400;

          i {
            font-size: 10px;
          }

          &:hover,
          :focus {
            color: $main-color;
          }

          &.active {
            color: $main-color !important;
          }

          &.active i {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }

          &:hover i {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            transition: 0.7s;
          }

          .active::before {
            transform: scale(1);
          }
        }

        .dropdown-menu {
          border: none;
          border-radius: 0;
          background-color: $color-white !important;

          li {
            border-bottom: 1px dotted rgba(148, 46, 89, 0.4);

            &:last-child {
              border-bottom: none;
            }

            a {
              color: $color-black;
              position: relative;
              transition: 0.7s;
              font-weight: 400;

              &::before {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 0;
                height: 1px;
                background-color: $main-color;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
              }

              &:hover::before {
                width: 100%;
              }

              &:hover,
              :focus,
              .active {
                color: $main-color;
                letter-spacing: 0.5px;
              }
            }
          }
        }
      }
    }
  }

  .other-side {
    .search-area {
      float: left;
      position: relative;
      top: 0;
      margin-right: 10px;

      .search-item {
        display: inline-block;
        position: relative;
        color: $color-black;
        cursor: pointer;
        top: 9px;

        i {
          display: inline-block;
          text-align: center;
          font-size: 16px;
          position: relative;
          top: -2px;
          color: $color-black;
          font-weight: 600;
        }

        .search-overlay {
          display: none;

          &.search-popup {
            position: absolute;
            top: 100%;
            width: 300px;
            right: 0;
            background: $color-white;
            z-index: 2;
            padding: 20px;
            -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
            margin-top: 20px;

            .search-form {
              position: relative;

              .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $color-black;
                outline: 0;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                padding-top: 4px;
                padding-left: 10px;

                &:focus {
                  border-color: $main-color;
                }
              }

              .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                padding: 0;
                cursor: pointer;

                &:focus {
                  color: $main-color;
                }
              }
            }
          }
        }

        .search-btn {
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          font-size: 16px;
          color: $color-black;

          &:hover {
            color: $main-color;
          }

          &:hover i {
            color: $main-color;
          }
        }

        .close-btn {
          cursor: pointer;
          display: none;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          color: $color-black;

          &:hover {
            color: $main-color;
          }

          &.active {
            display: block;
            margin-left: 5px;
            top: 1px;
          }
        }
      }
    }
  }
}

.navbar-light {
  padding-left: 0;
  padding-right: 0;

  .navbar-brand {
    img {
      float: left;
    }
  }
}

// .mobile-nav {
//     .mean-container {
//         .mean-nav {
//             ul {
//                 li {
//                     a {
//                         .active {
//                             color: $main-color;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// .mean-container {
//     a {
//         &.meanmenu-reveal {
//             color: $body-color;
//             span {
//                 background-color: $body-color;
//             }
//         }
//     }
// }
/**********************NAVBAR AREA CSS END*****************/
/**********************BANNER SLIDER HOME PAGE CSS START*****************/


.home-one {

  .course-archive-wrapper .course-hub-banner .slider-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: auto;
    text-align: left;
  }

  .course-hub-banner .slider-content {
    box-shadow: 0px 20px 60px 0px rgb(0 15 56 / 10%);
    border-radius: 10px;
    position: absolute;
    top: 80%;
    transform: translate(-50%, -80%);
    left: 22%;
    background: rgba(240, 240, 240, 0.6);
    padding: 30px;
    text-align: center;
  }

  .course-hub-banner .slider-content .slider-title {
    font-weight: 700;
    font-size: 50px;
  }

  .course-hub-banner .slider-content .slider-pretitle {
    font-size: 20px;
    font-weight: 500;
  }

  .course-hub-banner .slider-content #contact-form input {
    border-radius: 4px;
    border: 2px solid #2a6df5;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 35px;
    outline: none;
  }

  .course-hub-banner .slider-content #contact-form .back-btn {
    width: 100%;
    border-radius: 4px;
  }


  .owl-img {
    height: 550px;
    object-fit: cover;
  }

  .owl-theme {
    .owl-dots {
      position: absolute;
      top: 50%;
      right: 8%;
      transform: translateY(-50px);

      .owl-dot {
        display: grid;

        span {
          background-color: transparent;
          width: 15px;
          height: 15px;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          border: 1px solid $color-white;
          border-radius: 50%;
        }

        &:hover span {
          background-color: $color-white !important;
        }

        &.active span {
          background-color: $color-white !important;
        }
      }
    }
  }
}

/**********************BANNER SLIDER HOME PAGE CSS END*****************/
/**********************Facilities AREA HOME PAGE CSS START*****************/
// .course-area{
//     .course-item{
//         background-color: $color-white;
//         transform: 0.5s;
//         img{
//             width: 100%;
//             min-width: 100%;
//             border-top-left-radius: 5%;
//             border-top-right-radius: 5%;
//             box-shadow: 0 0 2px;
//         }
//         .course-content{
//             border-bottom-left-radius: 5%;
//             border-bottom-right-radius: 5%;
//             box-shadow: 0 0 2px;
//             h6 {
//                 color: #535353;
//             }
//             &:hover {
//                 background: linear-gradient(to left, #3180cf 50%, #fff 100%) right / 135% 100% no-repeat;
//                 h6,
//                 p {
//                     color: white;
//                 }
//             }
//         }
//         &:hover {
//             transform: translateY(-5px);
//         }
//     }
// }
.promo-section {
  padding: 40px 0;
  background: #fafafa !important;
}

.section-heading h2 {
  font-weight: 700;
  line-height: 1.25;
  font-family: "Inter", sans-serif;
  color: #363636;

}

.single-promo-hover {
  overflow: hidden;
  z-index: 1;
  box-shadow: rgb(5 13 54 / 5%) 5px 15px 30px 0px;
}

.single-promo-hover:before {
  content: "";
  position: absolute;
  right: -55px;
  width: 95px;
  height: 95px;
  bottom: -50px;
  border-radius: 35px;
  background-color: #0948b3;
  -webkit-transition: all .3s;
  transition: all .3s;
  z-index: -1;
}

/**********************FacilitiesHOME PAGE CSS END*****************/
/**********************VIEW BUTTON HOME PAGE CSS START*****************/
.view-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px !important;
  border-radius: 0;
  font-size: 1.4rem;
  border-radius: 5px;
}

/**********************VIEW BUTTON HOME PAGE CSS END*****************/
/**********************COMMON CSS  START*****************/
.course-area,
.events-area,
.gallery-area,
.home-testimonial {
  h1 {
    color: $main-color;
  }
}

.product-area {
  background-color: #fffafa;
  overflow: hidden;
}

.product-item {
  margin-bottom: 30px;
  background-color: $color-white;
  box-shadow: rgb(10 10 10 / 12%) 5px 15px 30px 0px;

  transition: 0.5s;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    max-width: 100%;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
  }

  .product-cotent {
    border-top: 1px solid #eeeeee;
    width: 100%;
    padding: 12px;
    display: inline-block;
    position: relative;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;

    &:hover {
      background: linear-gradient(to left, #3180cf 50%, #fff 100%) right / 135% 100% no-repeat;

      .j-name {
        color: white;
      }

      .j-title {
        color: white;
      }
    }

    .product-text {
      display: inline-block;

      h3 {
        font-size: 22px;
      }

      span {
        font-size: 20px;
        color: $main-color;
        font-weight: 600;
        display: block;
        padding: 0;
        margin: 0;
        line-height: 1;
      }
    }
  }
}

/*testimonials page*/
.testimonial-card-detail {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 3px rgb(5 13 54 / 5%);
  min-height: 310px;

  h4 {
    font-size: 14px;
  }

  p {
    font-size: 16px;
  }

  .rating > ul > i {
    color: #ee9c03;
  }
}


/*end testimonials page*/
/*start Blog page*/
.Blogs-card-detail {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 3px rgb(5 13 54 / 5%);
  min-height: 310px;
}

/*end Blog page*/
/*start Event page*/
.News-card-detail {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 3px rgb(5 13 54 / 5%);
  min-height: 310px;

  inner-banner {
    background: #1169db;
  }

  &:hover {
    background: linear-gradient(to left, #3180cf 50%, #fff 100%) right / 135% 100% no-repeat;
    color: white;

    .news-content {
      h3 {
        color: #fff;
      }

      p {
        color: #fff;
      }
    }
  }
}

/*end Event page*/
.scetion-title {
  span {
    font-size: 17px;
    color: $main-color;
    font-weight: 600;
    line-height: 0;
  }

  h1 {
    color: $main-color;
  }

  h2 {
    font-size: 35px;
    margin-top: 2px;
  }

  p {
    max-width: 560px;
    margin: auto;
  }
}

.common-dtel-img {
  margin-bottom: 30px;
}

.common-dtel-text {
  h2 {
    font-size: 35px;
    max-width: 700px;
    margin-bottom: 20px;
  }

  p {
    max-width: 760px;
    font-size: 15px;
  }
}

/**********************COMMON CSS END*****************/
/**********************EVENT AREA HOME PAGE CSS START*****************/
.events-area {
  background-color: $color-white;

  .events-item {
    box-shadow: 0px 20px 60px 0px rgb(0 15 56 / 10%);
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    min-height: 31rem;

    .events-data {
      padding: 30px;

      .view-event {
        display: flex;
        justify-content: center;

        button {
          width: 100%;
        }
      }
    }

    ul {
      padding: 0;
      margin-top: 20px;

      li {
        padding: 1em 0;
        list-style-type: none;
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .event-title {
          color: black;
          font-weight: bold;
          font-size: 16px;
        }
      }

      li:last-child {
        border: 0;
      }
    }
  }
}

/**********************EVENT AREA HOME PAGE CSS END*****************/
/**********************GALLERY AREA HOME PAGE CSS START*****************/
.gallery-area {
  .gallery-item {
    position: relative;
    cursor: pointer;
    margin-right: 5px;

    .image {
      opacity: 1;
      display: block;
      border-radius: 5%;
      width: 100%;
      height: max(200px, 200px);
      transition: 0.5s ease;
      backface-visibility: hidden;
    }

    .middle {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;

      .text {
        font-size: 1.8rem;
        padding: 16px 32px;
        font-weight: 700;
      }
    }

    &:hover .image {
      opacity: 0.3;
    }

    &:hover .middle {
      opacity: 1;
    }
  }
}

/**********************GALLERY AREA HOME PAGE CSS END*****************/
/**********************TESTIMONIAL AREA HOME PAGE CSS END*****************/
.review-section {
  padding: 40px 0;
  background: #d2e2f1 !important;
  min-height: 450px;
}

.testimonial-card {

  border-radius: 20px !important;
  background-color: white;
  box-shadow: 0px 4px 50px rgb(0 6 18 / 10%);
  margin: 0 10px;
  min-height: 28rem;
  min-width: 31rem;
}

.testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.testimonial-items {
  background: #f8f9fb;
  padding: 30px;
  z-index: 2;
  border-radius: 5px;
  min-height: 32rem;
  max-height: 32rem;
}

.testimonial-img {
  img {
    margin-right: 20px;
  }
}

.testimonial-title {
  h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 2px;
  }

  span {
    font-size: 16px;
    margin-bottom: 2px;
    display: block;
  }
}

.testimoni-quotes {
  .fa-duotone {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: -1;
    content: "";
  }
}

.testimonial-body {
  margin-bottom: 20px;

  h3 {
    font-size: 20px;
    color: black;
    margin-bottom: 20px;
  }

  p {
    font-size: 17px;
  }

}

.testimonial-icon {
  i {
    color: #df6e0c;
    font-size: 15px;
  }
}

.testimonial-pagination {
  margin-top: 50px;
  line-height: 1;
}


/**********************TESTIMONIAL AREA HOME PAGE CSS END*****************/
/**********************FOOTER AREA CSS START*****************/
.footer-area {
  position: relative;
  background-color: #32363a;

}

.footer-list {
  margin-bottom: 30px;

  h3 {
    font-size: 23px;
    color: $color-white;
    position: relative;
    padding-left: 15px;
    margin-bottom: 20px;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 3px;
      height: 23px;
      background-color: $color-blue;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-top: 10px;
    display: block;
    font-size: 17px;
    color: $color-white;

    &:hover {
      color: #64acff;
    }

    i {
      width: 30px;
      height: 30px;
      background-color: #133c6c;
      line-height: 30px;
      text-align: center;
      margin-right: 5px;
      font-size: 15px;
      border-radius: 3px;
      transition: 0.7s;
    }

    &:hover i {
      border-radius: 50%;
      color: $color-white;
      background-color: $color-dark-blue;
    }

    a {
      color: $color-white;

      i {
        width: 30px;
        height: 30px;
        background-color: #133c6c;
        line-height: 30px;
        text-align: center;
        margin-right: 5px;
        font-size: 15px;
        border-radius: 3px;
        transition: 0.7s;
      }

      &:hover i {
        border-radius: 50%;
        color: $color-white;
        background-color: $color-dark-blue;
      }

      &:hover {
        color: $color-dark-blue;
      }
    }
  }
}

.footer-content {
  position: relative;
  margin-bottom: 30px;

  .footer-conten-title {
    p {
      color: $color-white;
      margin-top: 25px;
      max-width: 270px;
    }
  }

  .newsleter-area {
    h3 {
      font-size: 24px;
      color: #fff;
      margin-bottom: 15px;
    }

    .newsletter-form {
      position: relative;
      max-width: 300px;
      border-radius: 0px 5px 5px 0px;

      .form-control {
        background: $color-white;
        height: 50px;
        line-height: 52px;
        margin: 0;
        border: none;

        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
      }

      .subscribe-btn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        background: $color-dark-blue;
        color: $color-white;
        height: 41px;
        line-height: 54px;
        padding: 0 20px;
        border: 0;
        border-radius: 0px 5px 5px 0px;
        transition: 0.5;

        &:hover {
          background: $color-blue;
          color: $color-white;
        }
      }
    }
  }

  .footer-social {
    margin-top: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        width: 35px;
        height: 35px;

        text-align: center;
        margin-right: 5px;


        &:hover {
          color: #fff;
          border-radius: 50%;
        }

        a {
          color: $color-white;
          line-height: 40px;


          i {
            color: #fff;
          }
        }
      }
    }
  }
}

.footer-bottom {
  border-top: 1px solid $paragraph-color;
  padding: 20px 0;

  .bottom-list {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-right: 33px;

        a {
          color: $color-white;

          &:hover {
            color: $color-dark-blue;
          }
        }
      }
    }
  }

  .bottom-text {
    float: right;

    p {
      color: $color-white;
      padding: 0;
      margin: 0;

      a {
        color: $main-color;

        &:hover {
          color: $color-white;
        }
      }
    }
  }
}

/**********************FOOTER AREA CSS END*****************/
/**********************INNER BANNER CSS START*****************/
.inner-banner {
  position: relative;

  .inner-title {
    h3 {
      font-size: 45px;
      color: white;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 18px;
        color: $color-white;
        display: inline-block;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        i {
          font-size: 16px;
        }

        a {
          color: $color-white;

          &:hover {
            color: $main-color;
          }
        }
      }
    }
  }
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}

/**********************INNER BANNER CSS END*****************/
/**********************ABOUT DETAIL CSS START*****************/
.about-detl {
  .about-text {
    text-align: justify;
    color: black;
    font-size: 20px;
  }
}

.about-img {
  width: 27vw;
  border-radius: 5%;
  margin-top: 24px;
}

.breadcrumb-section {
  margin-top: 38px;
}

.AboutUs_ {
  .img-fluid {
    margin-top: 0%;
    margin-bottom: 5%;
  }

}

/**********************ABOUT DETAIL CSS END*****************/
/**********************COURSE DETAIL CSS START*****************/
.coursedetail-area {
  overflow: hidden;
  position: relative;
}

.courses-card-item {
  margin-bottom: 30px;
  background-color: $color-white;
  box-shadow: 0 0 15px rgba(239, 235, 235, 0.7);

  .courses-card-img {
    img {
      width: 100%;
    }
  }

  .courses-card-content {
    padding: 20px;

    h3 {
      font-size: 24px;
    }

    p {
      color: $body-color;
    }

    &:hover {
      background: linear-gradient(to left, #3180cf 50%, #fff 100%) right / 135% 100% no-repeat;

      h3,
      p {
        color: $color-white
      }
    }
  }
}

/**********************COURSE DETAIL CSS END*****************/
/**********************NEWS DETAIL CSS START*****************/
.news-card {
  margin-bottom: 30px;
  background-color: $color-white;
  box-shadow: 0 0 15px rgba(239, 235, 235, 0.7);

  &:hover .news-content a h3 {
    color: $main-color;
  }

  .news-img {
    position: relative;

    a {
      display: block;

      img {
        max-width: 100%;
      }
    }
  }

  ul {
    list-style: none;
    margin: 10px 0 0 0;
    width: 100%;
    padding-left: 20px;
    display: inline-block;

    li {
      display: inline-block;
      font-size: 14px;
      color: #8f95a9;
      margin-top: 15px;
      margin-right: 30px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 3px;
        height: 3px;
        background: $head-color;
        left: -15px;
        top: 10px;
      }

      &:first-child::before {
        display: none;
      }

      a {
        color: $color-blue;
      }
    }
  }

  .news-content {
    padding: 10px 20px 20px 20px;

    a {
      h3 {
        font-size: 20px;
        transition: 0.7s;
      }
    }

    p {
      color: $body-color;
      margin: 0;
    }

    .news-icon {
      margin-top: 8px;
      font-weight: 600;
      color: $body-color;

      &:hover {
        color: $main-color;
        letter-spacing: 0.5px;
      }
    }
  }
}

/**********************NEWS DETAIL CSS END*****************/
/**********************PAGINATION AREA CSS START*****************/
.pagination-area {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;

  .page-numbers {
    width: 40px;
    height: 40px;
    margin: 0 3px;
    text-align: center;
    display: inline-block;
    background-color: $color-white;
    line-height: 42px;
    color: $color-blue;
    font-weight: 600;
    font-size: 16px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    &.current,
    &:hover,
    &:focus {
      background: $main-color;
      color: $color-white;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

    i {
      font-size: 15px;
    }
  }
}

/**********************PAGINATION AREA CSS END*****************/
/**********************CAREER DETAIL AREA CSS START*****************/
.career-card {
  box-shadow: 0 0 1px;
  margin: 20px;
}

.career-content {
  padding: 20px 0 10px 20px;
}

/**********************CAREER DETAIL AREA CSS END*****************/
/**********************INSTRUCTOR DETAIL AREA CSS START*****************/
.instructor-content {
  P {
    font-size: 16px;
    color: black;
    text-align: justify;
  }
}

/**********************INSTRUCTOR DETAIL AREA CSS END*****************/
/**********************TESTIMONIAL DETAIL AREA CSS START*****************/
.single-testominal {
  background-color: $color-white;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-bottom: 30px;
  text-align: center;

  img {
    max-width: 20%;
    margin-bottom: 10px;
    border-radius: 50%;
    position: relative;
    border: 2px solid $color-blue;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  span {
    color: $head-color;
    font-size: 16px;
  }

  p {
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

/**********************TESTIMONIAL DETAIL AREA CSS END*****************/
/**********************GALLERY DETAIL AREA CSS START*****************/
.single-gallery {
  position: relative;
  margin-bottom: 30px;

  img {
    width: 100%;
  }

  &:hover img {
    filter: brightness(0.5);
  }
}

/**********************GALLERY DETAIL AREA CSS END*****************/
/**********************courses  AREA CSS start*****************/
.card-container {
  height: 26vh;
}

.clickable-card {
  display: block;
  max-width: 400px;
  margin-inline: auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: none;
  background: rgb(218, 214, 214);
  transform-origin: center center;
  transition: all 0.4s ease-in-out;
  text-decoration: none;
  color: black;
}

.clickable-card h2,
.clickable-card .read-more {
  transition: color 0.4s ease-in-out;
}

.clickable-card img {
  transform-origin: center;
  transition: transform 0.4s ease-in-out;
}

.clickable-card:hover .read-more,
.clickable-card:focus .read-more,
.clickable-card:hover h2,
.clickable-card:focus h2 {
  color: #28666e;
}

.clickable-card:hover img,
.clickable-card:focus img {
  transform: scale(1.1);
}

.article-body {
  padding: 24px;
}

.clickable-card h2 {
  margin: 0 0 18px 0;
  font-family: "Bebas Neue", cursive;
  font-size: 1.9rem;
  letter-spacing: 0.06em;
  transition: color 0.3s ease-out;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

/************************
  Generic layout
  **************************/

*,
*::before,
*::after {
  box-sizing: border-box;
}

.our-courses {
  font-weight: 700;
  line-height: 1.25;
  font-family: "Inter", sans-serif;
  color: #363636;
  font-size: 40px;
}

/**********************COURSE AREA CSS END*****************/

/**********************Principal AREA CSS start*****************/
.principal {
  padding-bottom: 2rem;
  background: #fafafa !important;
}

/**********************Principal AREA CSS END*****************/


/**********************Gallery AREA CSS Start*****************/

/* Parent Container */
.content_img {
  position: relative;
  width: 335px;
  height: 200px;
  float: left;
  margin-right: -9px;
  margin-top: 4rem;

  img {
    border-radius: 5px;
  }

}

/* Child Text Container */
.content_img div {
  position: absolute;
  bottom: -54px;
  right: 0;
  left: 3%;
  background: #0948B3;
  color: #fff;
  margin-bottom: -35px;

  width: 209px;
  height: 50px;
  padding: 8px 15px;
  visibility: visible;
  opacity: 0.9;
  min-width: 20.6rem;
  // opacity: 0;
  // visibility: hidden;
  // -webkit-transition: visibility 0s, opacity 0.5s linear;
  // transition: visibility 0s, opacity 0.5s linear;

}

/* Hover on Parent Container */
.content_img:hover {
  cursor: pointer;
}

// .content_img:hover div {}

/**********************Gallery AREA CSS END*****************/


/****   Edu course*****************/

.sub-title-box {
  padding: 8px 20px;
  font-size: 17px;
  background: rgb(10 108 206);
  color: white;
  border-radius: 20px;
  display: inline-block;
  line-height: 1;
}

.section-title {
  font-size: 30px;
  line-height: 1.3;
}

/**********************/
.facalitySection {
  padding: 40px 0;
  background: #ffffff !important;
}

.tp-cat-item {
  background: var(--tp-common-white);
  padding: 30px 30px;
  border-radius: 10px;

  & .tp-category-title {
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;

    font-weight: 500;

  }

  &:hover {
    box-shadow: rgba(5, 13, 54, 0.05) 5px 15px 30px 0px;

    & .tp-category-title {
      color: var(--tp-heading-secondary);
    }

    & .tp-category-icon {
      & img {
        transform: rotateY(180deg);
      }
    }
  }
}

.tp-category-icon {
  & img {
    width: 40px;
    height: auto;
    transition-duration: .6s;
  }
}

.tp-rec-item {
  box-shadow: rgb(10 10 10 / 12%) 5px 15px 30px 0px;
  min-height: 250px;

  .tp-r-cat-title {
    margin-bottom: 15px;
  }

  & p {
    margin-bottom: 0;
    line-height: 1.9;
  }

  .tp-category-icon {
    height: 10px;
  }
}

.tp-cat-content {
  & .tp-title-small {
    margin-bottom: 10px;

  }

  & p {
    margin-bottom: 0;
  }
}

.tp-cat-color {
  & span {
    & img {
      width: 35px;
      height: auto;
    }
  }
}

.tp-category-icon {
  & span {
    display: inline-block;
    width: 75px;
    height: 75px;
    line-height: 65px;
    text-align: center;
    border-radius: 50%;
  }
}

/****************************/
.feature-content-wrap {
  p {
    font-size: 17px;
  }
}

// News Page

$black: #666;

$green: #49b35e;
$red: #f43f31;
.custom-checkbox {
  position: relative;
  cursor: pointer;

  input[type=checkbox] {
    position: absolute;
    opacity: 0;

    + b {
      position: relative;
      display: inline-block;
      background-color: #fff;
      border: 2px solid #e5e5e5;
      border-radius: 4px;
      font-size: 20px;
      color: #49b35e;
      cursor: pointer;
      line-height: 1;
      outline: 0;
      text-align: center;
      vertical-align: bottom;
      height: 18px;
      width: 18px;
    }

    &:hover {
      + b {
        border-color: #49b35e;

        &:after {
          color: #49b35e;
          content: '\2714';
        }
      }
    }
  }
}
// Start Of Blog CSS
.blog_c{
  .blog-search {
      position: relative;
  }
  .Goto{
cursor: pointer;
margin-left: 56%;
  }
  .blog-search input {
      border: 0 none;
      background: var(--color-white);
      height: 60px;
      width: 100%;
      border-radius: 3px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      padding: 0 72px 0 25px;
  }
  .thumbnail img{
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}
.card{
  border-radius: 3% !important;
  box-sizing: border-box;
  height: 540px;
  overflow: hidden;
  height:auto;
}
.Next{
  margin-top: auto;
  margin-left: 50%;
}

  .card:hover {
      color: rgba(255, 255, 255, 1);
      box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
    }
  .blog-search .search-button {
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--color-primary);
      border: 0 none;
      height: 100%;
      width: 60px;
      text-align: center;
      color: var(--color-white);
      font-size: 20px;
      border-radius: 0px 3px 3px 0px;
  }
  .edu-blog-widget-2.widget-categories .category-list li {
      margin: 0;
      text-decoration: none;
  }
  .edu-blog-widget-2.widget-categories .category-list li a {
      display: flex;
      justify-content: space-between;
      position: relative;
  }
  .edu-blog-widget-2.widget-categories .category-list li+li {
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid #EEEEEE;
  }
  .thumbnail a {
      display: block;
      overflow: hidden;
  }
  .thumbnail a img {
      transition: 0.4s;
      overflow: hidden;
      width: 100%;
  }
  .content {
      padding: 20px;
      justify-content: flex-start;
  }
  .Calender{
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
  }
  
  .element {
      box-shadow: none;
  }
  .element:hover {
      box-shadow:rgb(245, 8, 8);
  }
  .searchBox{
      width: 100%;
      
  }
  .searchBox:hover {
      color: rgba(255, 255, 255, 1);
      box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
  }
  .blog-card-bottom {
      padding: 20px;
      display: flex;
      justify-content: space-between;
  }
  .latest-post,.post-content{
      font-family: Metric !important;
      color: #565e7c;
  }
  .latest-post {
      display: flex;
      align-items: center;
      position: relative;
  }
  .latest-post .thumbnail {
      margin-right: 25px;
  }
  
  .latest-post .thumbnail a {
      display: block;
  }
  .latest-post .thumbnail a img {
      border-radius: 5px;
      max-height: 100px;
      object-fit: cover;
  }
  img{
      height:300px;
  }
  .latest-post .post-content .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0;
  }
  .latest-post {
      padding-top: 20px;
      margin-top: 20px;
  }
  .latest-post::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: rgba(45, 40, 78, 0.07);
      content: "";
  }
  .eduvibe-status.status-05:hover {
      color: var(--color-primary);
  }
  style.css.eduvibe-status.status-05 {
      color: var(--color-body);
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      transition: 0.4s;
  }
  a {
      color: black;
  }
  a:hover,
  a:focus,
  a:active {
      text-decoration: none;
      outline: none;
      color: blue;
  }
  .edu-blog .blog-card-bottom {
      display: flex;
  
      align-items: center;
  }
  .ReadMore{
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
  }
  style.css a:hover,
  a:focus,
  a:active {
      text-decoration: none;
      outline: none;
      color: var(--color-primary);
  }
  
  ul li {
      list-style: none;
  }
  .category-list {
      padding: 0;
      margin: 0 auto;
      height:fit-content;
  }
  .title{
   
          font-family: Metric !important;
          color: #565e7c;
   
  }
  
      .edu-event .event-meta {
          display: flex;
          align-items: center;
          }
       .edu-event .event-meta ul {
          padding: 0;
          margin: 0;
          list-style: none;
          }
         
// .Next{
//   margin-left: 68% !important;
// }
    
      
}


// End Of Blog CSS

// Start of Blog Description CSS
.blog_des {

  .container {
    margin: auto;
  width: 60%;
  padding: 20px;
     margin-top: 3% !important;
  }
.descript{
  margin: auto;
  // width: 60%;
  padding: 40px;
}
  .Comment {
    margin-left: 1%;
    margin-top: 1%;
  }

  ul {
    display: flex;
    list-style-type: none;
    margin: 20;
    padding: 0;
    overflow: hidden;
    justify-content: space-between;
  }

  li a {

    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  li a:hover {
    background-color: #111;
  }

  .title {
    text-align: center;
  }

  /*-----------------------------
      Blog Comments Style
  ------------------------------*/
  .comment-list-wrapper .comment {
    display: flex;
  }

  @media only screen and (max-width: 575px) {
    .comment-list-wrapper .comment {
      flex-direction: column;
    }
    .comment-list-wrapper .comment .comment-content {
      margin-top: 20px;
    }
  }

  .comment-list-wrapper .comment .thumbnail {
    min-width: 70px;
    width: 70px;
    max-height: 70px;
    border-radius: 100%;
    margin-right: 25px;
  }

  .comment-list-wrapper .comment .thumbnail img {
    border-radius: 100%;
    width: 100%;

    margin-left: 5px;
  }

  .comment-list-wrapper .comment .comment-content .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
  }

  .comment-list-wrapper .comment .comment-content .date {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-body);
    display: block;
    margin-bottom: 20px;
  }

  .comment-list-wrapper .comment .comment-content p {
    margin-bottom: 20px;
  }

  .comment-list-wrapper .comment .comment-content .reply-btn {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }

  .comment-list-wrapper .comment .comment-content .reply-btn i {
    margin-right: 10px;
  }

  .comment-list-wrapper .comment + .comment {
    border-top: 1px solid #EEEEEE;
    padding-top: 30px;
    margin-top: 30px;
  }

}

// End of Blog Description CSS

// Start of Event CSS
.News_c {
  .container {
    position: relative;
    margin-top: 1%;
  }
  .CourseDetails{
    margin-left: 42% !important;
  }
  .thumbnail img{
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}
.card{
  border-radius: 3% !important;
}
.Goto{
  cursor: pointer;
}
  .top-left {
    box-sizing: border-box;
    position: absolute;
    top: 61%;
    left: 5px;
    border: 1px solid;
    border-radius: 20px;
    background-color: rgb(118, 171, 252);
  }

  .eventPage {
    margin-top: 7% !important;
  }

  .news-card:hover {
    background: linear-gradient(to left, #3180cf 50%, #fff 100%) right/135% 100% no-repeat;
    color: white;
  }

  .news-card:hover .news-content h3 {
    color: #fff;
  }

  .news-card:hover .news-content p {
    color: #fff;
  }

  .news-card .news-img img {
  
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .news-content .toggle {
    color: rgb(160, 158, 158);
  }

  .inner-title {
    width: 70%;
    margin-left: 17rem;
  }

  .inner-title h3 {
    color: #fff;
  }

  .inner-title .inner-h5 {
    margin-top: 3rem;
  }

  .news-content {
    border-radius: 5%;
    box-shadow: 0 0 2px;
    /* height: 40vh; */
    padding: 2rem;
  }

  .inner-title .inner-h5 h5 {
    color: #fff;
  }

  .pagination {
    position: relative;
    right: 35%;
    left: 23%;
    font-size: 20px;
    margin-top: 14px;
  }

  .thumbnail a {
    display: block;
    overflow: hidden;
  }

  .thumbnail a img {
    height: 370px;
    object-fit: cover;
    width: 100%;
    // overflow: hidden;
    // width: 100%;
  }

  .content {
    padding: 20px;
    justify-content: flex-start;
  }

  .title:hover {
    text-decoration: none;
    outline: none;
    color: blue;
  }

  i:hover {
    text-decoration: none;
    outline: none;
    color: blue;
  }

  img {
    height: 300px;
    width: 200px;
  }

  .eduvibe-status.status-05:hover {
    color: var(--color-primary);
  }

  style.css.eduvibe-status.status-05 {
    color: var(--color-body);
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    transition: 0.4s;
  }

  // a {
  //   color: black;
  // }

  // a:hover,
  // a:focus,
  // a:active {
  //   text-decoration: none;
  //   outline: none;
  //   color: blue;
  // }

  .blog-meta li {
    margin: 5px 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }
  .edu-blog .blog-card-bottom {
    display: flex;

    align-items: center;
  }

  a.btn-transparent,
  button.btn-transparent {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;

  }
  .btn-transparent{
margin-left: 49% !important;
  }
  ul li {
    list-style: none;
  }

  .category-list {
    padding: 0;
    margin: 0;
  }

  .title {

    font-family: Metric !important;
    color: #565e7c;

  }

  .card:hover {
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
  }


}

// End of Event CSS
// Start Of Event Description CSS
.Event_de {
  /* Event Details  */
  .edu-event-details .content .title {
    font-weight: 800;
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 30px;
  }
  .eduvibe-widget {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 30px;
  }
  .eduvibe-widget .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  .eduvibe-widget-details .widget-content ul li {
    display: flex;
    justify-content: space-between;
  }
  .lists {
    padding: unset;
  }
  .eduvibe-widget-details .widget-content ul li i {
    color: #525FE1;
    margin-right: 10px;
  }
  .eduvibe-widget-details .widget-content ul li img.eduvibe-course-sidebar-img-icon {
    max-width: 16px;
    margin-right: 10px;
  }
  .eduvibe-widget-details .widget-content ul li span {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    display: inline-block;
  }
  .eduvibe-widget-details .widget-content ul li + li {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #EEEEEE;
  }
  /* .widget-content{
    margin-left: -10%;
  } */
  a.edu-btn,
  button.edu-btn {
    border-radius: 5px;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    color: #FFFFFF;;
    background: #525FE1;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 700;
    transition: 0.4s;

    border: 0 none;
  }
  a.edu-btn:hover,
  button.edu-btn:hover {
    background: #231F40;
    color: #FFFFFF
  }
  .container {
    margin-top: 6%;
  }
}
// End of Event Description CSS
// Start of Course Description CSS 

.course_d{
.img-thumbnail {
    padding: 0.25rem;
    background-color: #ffffff;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    max-width: 100%;
    width: 27vw;
    }
}
