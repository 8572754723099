/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Metric';
  font-style: normal;
  font-weight: normal;
  src: local('Metric'), url('../../../metronic/common/fonts/fonts/Metric.woff') format('woff');
}


@font-face {
  font-family: 'MetricBold';
  font-style: normal;
  font-weight: normal;
  src: local('MetricBold'), url('../../../metronic/common/fonts/fonts/MetricBold.woff') format('woff');
}
