//
// Keenthemes Icons
//

@mixin ki($icon) {
  font-family: "Font Awesome 5 Duotone";
  font-style: normal;
  font-weight: 700;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  content: "#{$icon}";
}
