.table {
  width: $width;
  margin-bottom: 1rem !important;
  color: #3F4254 !important;
  background-color: transparent !important;
  border-collapse: collapse !important;


  &-bordered {
    border: 1px solid #EBEDF3 !important;
  }
}

tr.suktas {
  background: $bg-gray;
}

.table-responsives {
  display: block !important;
  width: 100% !important;
}

/**********Report Table **********/
.table-box:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.301) !important;
}

// table header uppercase
tr th {
  text-transform: uppercase;
  text-align: center !important;
  font-weight: bold !important;
}

tr td {
  text-align: left !important;
  padding: 0px 5px !important
}

.table-header {
  height: auto;
  background-color: #1373d3 !important;
  background-color: #2581be !important;
  background: linear-gradient(90deg, #1b2e82, #228daf, #1c3d8a) !important;
  color: white !important;
  font-family: Metric !important
}

// update product (opening stock thead background)
.os-theadbg {
  background-color: #e8f3ff42;
}


// trialbalance report
.trial_balance {
  thead {
    background: #f8fafb;

    th {
      text-align: center !important;
    }
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: left !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 35px;
  }

}

