@import url("https://rsms.me/inter/inter.css");

.hide {
  display: none;
  visibility: hidden;
  height: 0;
}

.pagination\:container {
  display: flex;
  align-items: center;
}

.arrow\:text {
  display: block;
  vertical-align: middle;
  font-size: 13px;
  vertical-align: middle;
}

.pagination\:number {
  --size: 26px;
  --margin: 4px;
  margin: 0 var(--margin);
  border-radius: 6px;
  background: #f8fafb;
  border: 1px solid #dae3e7;
  box-shadow: -2px 2px 1px 1px rgb(179 179 179 / 14%);
  color: black;
  max-width: auto;
  min-width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 6px;
}

@media (hover: hover) {
  .pagination\:number:hover {
    color: black;
    background: #c0edfc;
  }
}

.pagination\:number:active {
  color: white;
  background: #0A6ED1;
  border: 1px solid #0A6ED1;
}

.pagination\:active {
  color: white;
  background: #0A6ED1;
  border: 1px solid #0A6ED1;
  position: relative;
}

.paginator-ngselect {

  position: relative;
  top: 5px;

  .ng-select {
    width: 65px !important;

    .ng-clear-wrapper {
      display: none !important;
    }

    .ng-select-container {
      border-radius: 7px !important;
    }
  }

}

.custom-paginator-ngselect {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    overflow: hidden;
    text-align: center;
    max-height: 500px;
    border-radius: 7px !important;
  }

  .ng-option-label {
    font-size: 14px !important;
  }

}
