
.col-2 {
  flex: 0 0 16.66666667% !important;
  max-width: 16.66666667% !important;
}

.col-4 {
  flex: 0 0 33.33333333% !important;
  max-width: 33.33333333% !important;
}

.col-6 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.col-8 {
  flex: 0 0 66.66666667% !important;
  max-width: 66.66666667% !important;
}

.col-10 {
  flex: 0 0 83.33333333% !important;
  max-width: 83.33333333% !important;
}

.col-12 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.fx-column {
  flex-direction: $flex-column;
}

// col-md
.col-md-12 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

// col xl
.col-xl-5 {
  flex: 0 0 41.66666667% !important;
  max-width: 41.66666667% !important;
}


.col-xl-7 {
  flex: 0 0 58.33333333% !important;
  max-width: 58.33333333% !important;
}
